import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Urls} from '../utils/urls';
import {Subject} from 'rxjs';

@Injectable()
export class VenueService {

  constructor(private http: HttpClient) {
  }

  getVenue(venueId) {
    return new Promise((resolve, reject) => {
      this.http
        .get(Urls.venueList + "/" + venueId )
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
}
