import {NbGlobalPhysicalPosition, NbToastrService} from '@nebular/theme';
import {Injectable} from '@angular/core';

@Injectable()
export class NotificationService {
  constructor(private _toastService: NbToastrService) {
  }
  showNotification(status, message ) {
    this._toastService.show(message, null , {
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      status,
      preventDuplicates: false,
      duration: 10000
    });
  }
}
export enum NotificationStatus {
  ERROR= 'danger',
  SUCCESS = 'success',
  INFO = 'info'
}
