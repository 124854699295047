import {Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {WidgetService} from "../../../services/widget.service";
import {NotificationService, NotificationStatus} from '../../../services/notification.service';
import {DateTimezoneHelper} from "../../../utils/helper/date-timezone.helper";
import * as moment from 'moment';
import {NbDialogRef, NbDialogService} from "@nebular/theme";

@Component({
  selector: 'app-takeaway-service',
  templateUrl: './takeaway-service.component.html',
  styleUrls: ['./takeaway-service.component.scss']
})
export class TakeawayServiceComponent implements OnInit {
  @Input() serviceList;
  @Input() bookingData;
  venueId;
  widgetSettings;
  isLoading = false;
  timeSlotList = [];
  isTimeSlot = false;
  currentSelectedService;
  @ViewChild('dialog') cardDialog;
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();

  constructor(private widgetService: WidgetService,
              private notificationService: NotificationService,
              private dialogService: NbDialogService,
              @Optional() private dialogRef: NbDialogRef<any>,) {
  }

  ngOnInit(): void {
    if(this.bookingData.booking.serviceId) {
      this.timeSlotList = this.serviceList.find(service => service._id === this.bookingData.booking.serviceId).timeSlot;
      const time = moment(this.bookingData.booking.pickUpTime).format("HH:mm");
      console.log(time)
      this.timeSlotList.forEach(timeSlot => {
        if (time === timeSlot.timeSlot) {
          timeSlot.isSelected = true;
          this.isTimeSlot = true;
        }
      });
    }
  }

  onNextClick() {
    if (this.checkValidations()) {
      return;
    }
    if(this.currentSelectedService.type === 'Services' && this.currentSelectedService.isPolicyAgreement){
      this.open();
    } else{
      this.onNextClickChange.emit();
    }
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }

  checkValidations() {
    if (!this.bookingData.booking.serviceId) {
      this.notificationService.showNotification(NotificationStatus.ERROR, 'Please select a service first.');
      return true;
    }
    if (!this.isTimeSlot) {
      this.notificationService.showNotification(NotificationStatus.ERROR, 'Please select time.');
      return true;
    }
  }

  onServiceSelected(service: any, index) {
    this.serviceList.forEach((service, i) => {
      if (i === index) {
        service.isSelected = !service.isSelected;
      } else {
        service.isSelected = false;
      }
      if (service.isSelected) {
        this.currentSelectedService = service;
        this.bookingData.booking.serviceId = service._id;
        this.widgetService.setSelectedService(service);
        this.timeSlotList = service.timeSlot;
      }
    });
    this.serviceList[index].isSelected = true;
  }

  onTimeSlotSelected(ts, index) {
    this.timeSlotList.forEach((timeslot, i) => {
      if (i === index) {
        timeslot.isSelected = !timeslot.isSelected;
      } else {
        timeslot.isSelected = false;
      }
      if (timeslot.isSelected) {
        const date = moment(this.bookingData.booking.pickUpTime)
          .set('hour', ts.timeSlot.split(':')[0])
          .set('minute', ts.timeSlot.split(':')[1])
          .set('seconds' , 0)
          .toDate();
        this.bookingData.booking.pickUpTime = date;
        this.isTimeSlot = true;
      }
    });
  }
  open() {
    this.dialogRef = this.dialogService.open(this.cardDialog, { context: 'this is some additional data passed to dialog' });
  }
  onPolicyAgreement(event){
    if(event){
      this.bookingData.exitDateTime = new Date(moment(this.bookingData.bookingDateTime)
        .add('minute', parseInt(this.bookingData.duration))
        .toDate());
      this.dialogRef.close();
      this.onNextClickChange.emit();
    }
  }
}
