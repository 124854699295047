<nb-layout>
  <nb-layout-column>
    <div class="bottom-main-div">
      <div class="header-div">
      </div>
      <div class="preview-content">
        <div class="logo-main">
          <div class="logo-image-div">
            <img src="../../assets/images/logo.png"/>
          </div>
        </div>
        <div class="main-content mt-70">
          <p>There is something wrong with the URL you have entered. Please check the URL and try again.</p>
        </div>
      </div>
    </div>

  </nb-layout-column>
</nb-layout>
