<nb-layout>
  <nb-layout-column>
    <div *ngIf="widgetSettings" class="bottom-main-div">
      <div class="header-div">
      </div>
      <div *ngIf="!isPaymentDone" class="preview-content">
        <div class="logo-main">
          <div class="logo-image-div">
            <img [src]="widgetSettings.venueId.logo"/>
          </div>
          <div class="venue-name mt-20">{{widgetSettings.venueId.venueName}}</div>
        </div>
        <date-selector (dateChange)="dateChange($event)" (onBackClickChange)="onBackClick()"
                       (onNextClickChange)="onNextClick()"
                       [isNext]="isNext"
                       [hidden]="bookingStep !== 0"
                       [bookingData]="bookingData"
                       class="w-100 overflow-y"></date-selector>
        <service-selector (onBackClickChange)="onBackClick()" (onNextClickChange)="onNextClick()"
                          [bookingData]="bookingData"
                          [serviceList]="serviceList"
                          [token]="token"
                          [tempDate]="tempDate"
                          [selectedService] = selectedService
                          [hidden]="bookingStep !== 1"
                          [isShowSection]="widgetSettings.widgetSettings.allowChooseSection"
                            class="w-100"></service-selector>
        <booking-selector (onBackClickChange)="onBackClick()"
                          (onNextClickChange)="onNextClick()"
                          [bookingData]="bookingData"
                          [hidden]="bookingStep !== 2"
                          class="w-100"></booking-selector>
        <custome-details (onBackClickChange)="onBackClick()" (onNextClickChange)="onNextClick()"
                         [hidden]="bookingStep !== 3" [bookingData]="bookingData"
                         [(customerData)]="customerData"
                         class="w-100"></custome-details>
        <booking-summary (onBackClickChange)="onBackClick()" (onNextClickChange)="onNextClick()"
                         (onHomeClickChange)="onHomeClick()"
                         [token]="token"
                         (stripeDataChange)="stripeDataChanged($event)"
                         *ngIf="bookingStep === 4" [bookingData]="bookingData" [customerData]="customerData"
                         class="w-100"></booking-summary>
      </div>
      <div *ngIf="isPaymentDone" class="preview-content message-div">
        <div class="thank-you-main-container">
          <div class="logo-image-div">
            <img [src]="widgetSettings.venueId.logo"/>
          </div>
          <p [innerHTML]="widgetSettings.widgetMessages.finalWidgetScreenMessage"></p>
        </div>
      </div>
      <div *ngIf="!isOnlineBooking" class="preview-content">
        <div class="thank-you-main-container">
          <div class="logo-image-div">
            <img [src]="widgetSettings.venueId.logo"/>
          </div>
          <p [innerHTML]="widgetSettings.widgetMessages.onlineBookingOff"></p>
        </div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>

