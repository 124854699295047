/* tslint:disable:align */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import {
  NotificationService,
  NotificationStatus,
} from '../../../services/notification.service';
import { DateTimezoneHelper } from '../../../utils/helper/date-timezone.helper';
import { BookingData } from '../../../utils/model/bookingData';
import * as moment from 'moment';
import { BookingWidgetModel } from '../../../utils/model/booking-widget.model';
import { createLogErrorHandler } from '@angular/compiler-cli/ngcc/src/execution/tasks/completion';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ActivatedRoute } from '@angular/router';

declare var $;
@Component({
  selector: 'service-selector',
  templateUrl: './service-selector.component.html',
  styleUrls: ['./service-selector.component.scss'],
})
export class ServiceSelectorComponent implements OnInit, OnChanges {
  @Input() serviceList;
  @Input() bookingData: BookingData;
  @Input() isShowSection;
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Input() selectedService;
  @Input() tempDate;
  @Input() token;
  jquary;
  timeslot;
  excludeBookingId;
  people = [];
  sectionList = [];
  bookingOptionList = [];
  timeSlotList = [];
  widgetSettings: BookingWidgetModel;
  isMAxPersonError = false;
  bookingType;
  isLoading = false;
  isSectionLoading = false;
  isNext = false;
  currentSelectedService = null;
  policyAgreement = false;
  @ViewChild('dialog') cardDialog;
  constructor(
    private widgetService: WidgetService,
    private notificationService: NotificationService,
    private dialogService: NbDialogService,
    @Optional() private dialogRef: NbDialogRef<any>,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.widgetSettings = this.widgetService.getWidgetSettingsFromLocal();
    if (changes.serviceList && changes.serviceList.currentValue.length) {
      changes.serviceList.currentValue.forEach((service) => {
        service.isSelected = false;
      });

      if (
        (this.bookingData.serviceId && this.token) ||
        this.activatedRoute.snapshot.queryParams.id
      ) {
        this.setPeople();
        this.serviceList.forEach((service, i) => {
          if (service._id === this.bookingData.serviceId) {
            this.excludeBookingId = this.bookingData._id;
            this.onServiceSelected(service, i, 'edit');
          }
        });
      }
      if (this.token) {
        this.isNext = true;
      }
    }
    if (changes.tempDate && changes.tempDate.currentValue) {
      this.sectionList = [];
      this.timeSlotList = [];
      this.currentSelectedService = null;
    }
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }

  onNextClick() {
    if (this.checkValidations()) {
      return;
    }
    if (
      (this.currentSelectedService.type === 'Services' &&
        this.currentSelectedService.isPolicyAgreement) ||
      (this.currentSelectedService.type === 'Event' &&
        this.currentSelectedService.settings.policyAgreement)
    ) {
      this.open();
    } else {
      this.bookingData.exitDateTime = new Date(
        moment(this.bookingData.bookingDateTime)
          .add('minute', parseInt(this.bookingData.duration))
          .toDate()
      );
      if (!this.bookingOptionList.length) {
        this.onNextClickChange.emit();
      }
      this.onNextClickChange.emit();
    }
  }

  checkValidations() {
    if (this.bookingType === 'event') {
      if (!this.bookingData.eventId) {
        this.notificationService.showNotification(
          NotificationStatus.ERROR,
          'Please select a event first.'
        );
        return true;
      }
    } else {
      if (!this.bookingData.serviceId) {
        this.notificationService.showNotification(
          NotificationStatus.ERROR,
          'Please select a service first.'
        );
        return true;
      }
    }
    if (this.isShowSection && !this.bookingData.sectionIds.length) {
      this.notificationService.showNotification(
        NotificationStatus.ERROR,
        'Please select a section.'
      );
      return true;
    }
    const selectedList = [];
    this.timeSlotList.forEach((ts) => {
      if (ts.isSelected) {
        selectedList.push(ts.isSelected);
      }
    });
    if (!selectedList.includes(true)) {
      this.notificationService.showNotification(
        NotificationStatus.ERROR,
        'Please select time.'
      );
      return true;
    }
    if (
      this.bookingOptionList.length !== 0 &&
      this.bookingData.bookingOptions.length === 0
    ) {
      this.notificationService.showNotification(
        NotificationStatus.ERROR,
        'Please select booking option.'
      );
      return true;
    }
  }

  increasePeople() {
    this.bookingData.people++;
    this.setPeople();
    this.checktables();
  }

  decreasePeople() {
    if (this.bookingData.people > 0) {
      this.bookingData.people--;
    }
    this.setPeople();
    this.checktables();
  }

  setPeople() {
    if (
      this.widgetSettings.widgetSettings.maxPeopleAllowedOnline > 0 &&
      this.bookingData.people >
        this.widgetSettings.widgetSettings.maxPeopleAllowedOnline
    ) {
      this.notificationService.showNotification(
        NotificationStatus.ERROR,
        this.widgetSettings.widgetMessages.maxPeopleInOnlineBooking
          ? this.widgetSettings.widgetMessages.maxPeopleInOnlineBooking
          : `Booking is available for maximum ${this.widgetSettings.widgetSettings.maxPeopleAllowedOnline} person`
      );
      this.isMAxPersonError = true;
      return;
    } else {
      this.isMAxPersonError = false;
    }
  }

  changeNoPeople(event) {
    this.bookingData.people = event.target.value;
    this.setPeople();
    this.checktables();
  }

  onServiceSelected(service: any, index, type?) {
    this.serviceList.forEach((service, i) => {
      if (i === index) {
        service.isSelected = !service.isSelected;
      } else {
        service.isSelected = false;
      }
      if (service.isSelected) {
        this.currentSelectedService = service;
        this.bookingOptionList = [];
        if (service.paymentType === 'booking-option') {
          service.bookingOptions.forEach((bookingOption) => {
            this.bookingOptionList = [
              ...this.bookingOptionList,
              ...bookingOption.bookingOptionId,
            ];
          });
          this.bookingOptionList.map(
            (data) => (data.bookingType = service.bookingOptions[0].bookingType)
          );
          this.bookingOptionList.forEach((bookingOptionList) => {
            bookingOptionList.isSelected = false;
            this.bookingData.bookingOptions.forEach((bookingOption) => {
              if (bookingOption.bookingOptionId === bookingOptionList._id) {
                bookingOptionList.isSelected = true;
              }
            });
          });
        }
        if (service.type === 'Event') {
          this.bookingData.duration = service.settings.bookingDuration;
          this.bookingData.eventId = service._id;
          this.bookingData.serviceId = null;
          this.bookingType = 'event';
          this.bookingData.bookingOptions = [];
          this.widgetService.setBookingType(this.bookingType);
        } else {
          this.bookingData.duration = service.bookingDuration;
          for (let duration of service.customBookingDuration) {
            if (
              this.bookingData.people >= duration.minPeople &&
              this.bookingData.people <= duration.maxPeople
            ) {
              this.bookingData.duration = duration.bookingDuration;
              break;
            }
          }
          this.bookingData.serviceId = service._id;
          this.bookingData.eventId = null;
          this.bookingType = 'service';
          this.widgetService.setBookingType(this.bookingType);
        }
        this.widgetService.setSelectedService(service);
        if (this.bookingData.people > 0) {
          this.isSectionLoading = true;
          this.getSectionsByService(service._id, service, type);
        } else {
          service.isSelected = !service.isSelected;
          this.currentSelectedService = null;
          this.notificationService.showNotification(
            NotificationStatus.ERROR,
            'Please select no. of people first'
          );
          this.isLoading = false;
        }
      }
    });
  }

  async getSectionsByService(serviceId, service, type?) {
    this.timeSlotList = [];
    const data = {
      bookingDate: new DateTimezoneHelper().convertDateWithTimezone(
        this.bookingData.bookingDateTime,
        this.widgetService.getVenue().timezone
      ),
      bookingType: this.bookingData.bookingType,
      people: this.bookingData.people,
      duration: this.bookingData.duration,
      readyToShareTable: false,
      sections: [],
      timezone: this.widgetService.getVenue().timezone,
    };
    if (service.type === 'Event') {
      data.bookingDate = this.tempDate;
      if (this.excludeBookingId) {
        data['excludeBookingId'] = this.excludeBookingId;
      }
      try {
        const res = await this.widgetService.getEventByIdAndDate(
          serviceId,
          data
        );
        if (!this.isShowSection) {
          this.sectionList = res.sections;
          this.isSectionLoading = false;
          if (!this.sectionList.length) {
            this.notificationService.showNotification(
              NotificationStatus.ERROR,
              'No section or tables are available for selected service.'
            );
          }
        } else {
          this.sectionList = res.sections;
          this.isSectionLoading = false;
          let checkSection = true;
          if (type === 'edit' && this.bookingData.serviceId && this.token) {
            res.data.sections.forEach((section, i) => {
              if (this.bookingData.sectionIds[0] === section.sectionId) {
                this.onSectionSelect(section, i);
                checkSection = false;
              }
            });
          }
          if (checkSection) {
            this.bookingData.sectionIds = [];
            this.bookingData.bookingDateTime = moment(
              this.bookingData.bookingDateTime
            )
              .set('hour', 0)
              .set('minute', 0)
              .toDate();
          }
          if (type !== 'edit') {
            this.bookingData.timeslot = '';
            this.checktables();
          }
        }
        setTimeout(() => {
          this.autoScroll();
        }, 100);
      } catch (err) {
        this.isSectionLoading = false;
        this.isLoading = false;
        this.notificationService.showNotification(
          NotificationStatus.ERROR,
          err.error.message ? err.error.message : 'Something went wrong'
        );
      }
    } else {
      if (this.excludeBookingId) {
        data['excludeBookingId'] = this.excludeBookingId;
      }
      try {
        const res = await this.widgetService.getSectionByService(
          serviceId,
          data
        );
        if (!this.isShowSection) {
          this.sectionList = res.data.sections;
          this.isSectionLoading = false;
          if (!this.sectionList.length) {
            this.notificationService.showNotification(
              NotificationStatus.ERROR,
              'No section or tables are available for selected service.'
            );
          }
        } else {
          this.sectionList = res.data.sections;
          this.isSectionLoading = false;
          let checkSection = true;
          if (type === 'edit' && this.bookingData.serviceId && this.token) {
            res.data.sections.forEach((section, i) => {
              if (this.bookingData.sectionIds[0] === section.sectionId) {
                this.onSectionSelect(section, i, type);
                checkSection = false;
              }
            });
          }
          if (checkSection) {
            this.bookingData.sectionIds = [];
            this.bookingData.bookingDateTime = moment(
              this.bookingData.bookingDateTime
            )
              .set('hour', 0)
              .set('minute', 0)
              .toDate();
          }
          if (!res.data.timeslot.length) {
            this.notificationService.showNotification(
              NotificationStatus.ERROR,
              'No time slots are available for selected service.'
            );
          }
          this.timeSlotList = [];
          if (type !== 'edit') {
            this.bookingData.timeslot = '';
            this.checktables();
          }
        }
        setTimeout(() => {
          this.autoScroll();
        }, 100);
      } catch (err) {
        this.isLoading = false;
        this.isSectionLoading = false;
        this.notificationService.showNotification(
          NotificationStatus.ERROR,
          err.error.message ? err.error.message : 'Something went wrong'
        );
      }
    }
  }

  onSectionSelect(section: any, index: number, type?) {
    this.sectionList.forEach((section, i) => {
      if (i === index) {
        section.isSelected = !section.isSelected;
      } else {
        section.isSelected = false;
      }
      if (section.isSelected) {
        this.bookingData.sectionIds = [];
        this.bookingData.sectionIds.push(section.sectionId);
        this.widgetService.setSelectedSection(section);
        if (this.bookingData.people > 0) {
          this.isLoading = true;
          this.timeSlotList = [];
          this.checkForTimeSlots(type);
        } else {
          section.isSelected = false;
          this.notificationService.showNotification(
            NotificationStatus.ERROR,
            'Please select no. of people first'
          );
        }
      }
    });
  }

  checkForTimeSlots(type?) {
    this.widgetService.setBookingData(this.bookingData);
    let checkId;
    if (this.bookingType === 'event') {
      checkId = this.bookingData.eventId;
    } else {
      checkId = this.bookingData.serviceId;
    }
    if (!checkId) {
      this.notificationService.showNotification(
        NotificationStatus.ERROR,
        'Please select a service first.'
      );
      return;
    }
    if (!this.bookingData.people || this.bookingData.people <= 0) {
      this.notificationService.showNotification(
        NotificationStatus.ERROR,
        'Please enter number of people.'
      );
      return;
    }
    if (!this.bookingData.duration) {
      this.notificationService.showNotification(
        NotificationStatus.ERROR,
        'Please enter duration.'
      );
      return;
    }
    if (!this.bookingData.sectionIds.length) {
      this.notificationService.showNotification(
        NotificationStatus.ERROR,
        'Please select a section.'
      );
      return;
    }
    const data = {
      bookingDate: new DateTimezoneHelper().convertDateWithTimezone(
        this.bookingData.bookingDateTime,
        this.widgetService.getVenue().timezone
      ),
      bookingType: this.bookingData.bookingType,
      people: parseInt(this.bookingData.people.toString()),
      duration: this.bookingData.duration,
      sections: this.bookingData.sectionIds,
      readyToShareTable: this.bookingData.readyToShareTable,
      timezone: this.widgetService.getVenue().timezone,
    };
    let id;
    if (this.bookingType === 'event') {
      id = this.bookingData.eventId;
    } else {
      id = this.bookingData.serviceId;
    }
    if (this.bookingType === 'event') {
      data.bookingDate = this.tempDate;
      if (this.excludeBookingId) {
        data['excludeBookingId'] = this.excludeBookingId;
      }

      this.widgetService
        .getEventByIdAndDate(id, data)
        .then((res: any) => {
          this.timeSlotList = [];
          if (res.timeslot.length > 0) {
            this.isLoading = false;
          }
          if (!res.timeslot.length) {
            this.notificationService.showNotification(
              NotificationStatus.ERROR,
              'No time slots are available for selected service.'
            );
          }
          res.timeslot.forEach((ts) => {
            if (
              type === 'edit' &&
              this.token &&
              this.bookingData.sectionIds[0] &&
              this.bookingData.timeslot
            ) {
              if (
                ts.timeslot ===
                moment(this.bookingData.bookingDateTime).format('HH:mm')
              ) {
                this.timeSlotList.push({
                  timeSlot: ts,
                  isSelected: true,
                });
              } else {
                this.timeSlotList.push({
                  timeSlot: ts,
                  isSelected: false,
                });
              }
            } else {
              this.timeSlotList.push({
                timeSlot: ts,
                isSelected: false,
              });
            }
          });
          setTimeout(() => {
            this.autoScroll();
          }, 100);
          this.checktables();
        })
        .catch((err: any) => {
          console.log(err);
          this.notificationService.showNotification(
            NotificationStatus.ERROR,
            err.error.message ? err.error.message : 'Something went wrong'
          );
        });
    } else {
      if (this.excludeBookingId) {
        data['excludeBookingId'] = this.excludeBookingId;
      }
      this.widgetService
        .getBookingTimeSlots(id, data)
        .then((res: any) => {
          this.timeSlotList = [];

          if (res.data.timeslot.length > 0) {
            this.isLoading = false;
          }
          if (!res.data.timeslot.length) {
            this.notificationService.showNotification(
              NotificationStatus.ERROR,
              'No time slots are available for selected service.'
            );
          }
          res.data.timeslot.forEach((ts) => {
            if (
              type === 'edit' &&
              this.token &&
              this.bookingData.sectionIds[0] &&
              this.bookingData.timeslot
            ) {
              if (
                ts.timeslot ===
                moment(this.bookingData.bookingDateTime).format('HH:mm')
              ) {
                this.timeSlotList.push({
                  timeSlot: ts,
                  isSelected: true,
                });
              } else {
                this.timeSlotList.push({
                  timeSlot: ts,
                  isSelected: false,
                });
              }
            } else {
              this.timeSlotList.push({
                timeSlot: ts,
                isSelected: false,
              });
            }
          });
          setTimeout(() => {
            this.autoScroll();
          }, 100);
          if (type !== 'edit') {
            this.checktables();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  onTimeSlotSelected(ts, index, type?) {
    this.bookingData.timeslot = ts.timeSlot.timeslot;
    this.timeSlotList.forEach((timeslot, i) => {
      if (i === index) {
        timeslot.isSelected = !timeslot.isSelected;
      } else {
        timeslot.isSelected = false;
      }
      if (timeslot.isSelected) {
        this.bookingData.tables = [];
        const date = moment(this.bookingData.bookingDateTime)
          .set('hour', ts.timeSlot.timeslot.split(':')[0])
          .set('minute', ts.timeSlot.timeslot.split(':')[1])
          .toDate();
        this.bookingData.bookingDateTime = date;
      }
    });
    this.checktables();
  }

  getBookingOption(data) {
    let bookingOption = {
      name: data.name,
      bookingOptionId: data._id,
      quantity: 0,
      pricePerPerson: data.pricePerPerson,
      bookingType: data.bookingType,
      totalPrice: data.pricePerPerson,
      bookingList: [],
      childBookingOption: [],
      applyForAll: true,
      widgetDescription: data.widgetDescription,
    };

    data.childBookingOption.forEach((ele) => {
      const childBookingOption = {
        name: ele.bookingOptionId.name,
        bookingOptionId: ele.bookingOptionId._id,
        quantity: 0,
        pricePerPerson: ele.bookingOptionId.pricePerPerson,
        bookingType: ele.bookingOptionType,
      };
      bookingOption.childBookingOption.push(childBookingOption);
    });
    bookingOption.bookingList.push(bookingOption.childBookingOption);
    let notCopy = true;
    this.bookingData.bookingOptions.forEach((ele, index) => {
      if (ele.bookingOptionId === data._id) {
        this.bookingData.bookingOptions.splice(index, 1);
        notCopy = false;
      } else {
        notCopy = true;
      }
    });
    if (notCopy) {
      bookingOption.quantity = 0;
      this.bookingData.bookingOptions.push(bookingOption);
    }
  }

  async checktables() {
    this.isNext = false;
    if (
      !this.bookingData.people ||
      (this.bookingType === 'service' && !this.bookingData.serviceId) ||
      (this.bookingType === 'event' && !this.bookingData.eventId) ||
      !this.bookingData.sectionIds.length ||
      !this.bookingData.timeslot
    ) {
      return;
    }
    try {
      const bookingData: BookingData = JSON.parse(
        JSON.stringify(this.bookingData)
      );
      bookingData.people = parseInt(this.bookingData.people.toString(), 10);
      let date;
      if (this.timeslot) {
        date = moment(bookingData.bookingDateTime)
          .clone()
          .set('hour', this.bookingData.timeslot.split(':')[0])
          .set('minute', this.bookingData.timeslot.split(':')[1])
          .toDate();
      } else {
        date = moment(bookingData.bookingDateTime).clone().toDate();
      }

      bookingData.bookingDateTime = moment(date)
        .clone()
        .tz(this.widgetService.getVenue().timezone, true)
        .utc()
        .toISOString();
      bookingData.exitDateTime = moment
        .utc(bookingData.bookingDateTime)
        .clone()
        .add(parseInt(bookingData.duration, 10), 'minutes')
        .toISOString();
      const data: any = {
        bookingDateTime: bookingData.bookingDateTime,
        bookingType: this.bookingData.bookingType,
        readyToShareTable: bookingData.readyToShareTable,
        people: bookingData.people,
        sectionIds: bookingData.sectionIds,
        [bookingData.serviceId ? 'serviceId' : 'eventId']: bookingData.serviceId
          ? bookingData.serviceId
          : bookingData.eventId,
        tables: bookingData.tables,
        exitDateTime: bookingData.exitDateTime,
      };
      const res = await this.widgetService.checkTableAvailability(data);
      setTimeout(() => {
        this.autoScroll();
      }, 100);
      if (res) {
        this.isNext = true;
      }
    } catch (err) {
      console.log(err);
      this.isNext = false;
      this.notificationService.showNotification(
        NotificationStatus.ERROR,
        err.error.message
      );
    }
  }
  open() {
    this.dialogRef = this.dialogService.open(this.cardDialog, {
      context: 'this is some additional data passed to dialog',
    });
  }
  onPolicyAgreement(event) {
    if (event) {
      this.bookingData.exitDateTime = new Date(
        moment(this.bookingData.bookingDateTime)
          .add('minute', parseInt(this.bookingData.duration))
          .toDate()
      );
      this.dialogRef.close();
      if (!this.bookingOptionList.length) {
        this.onNextClickChange.emit();
      }
      this.onNextClickChange.emit();
    }
  }

  autoScroll() {
    const serviceSelector = document.getElementById('service-selector');
    serviceSelector.scrollTop = serviceSelector.scrollHeight;
  }
}
