import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BookingData } from '../../../utils/model/bookingData';
import * as moment from "moment";

@Component({
  selector: 'date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent implements OnInit {
  lastDate;
  @Input() isNext = false;
  @Input() bookingData: BookingData;
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Output() dateChange = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    this.lastDate = new Date(new Date().setDate(new Date().getDate() - 1));
  }

  onNextDate() {
    this.bookingData.bookingDateTime = new Date(
      new Date(this.bookingData.bookingDateTime).setMonth(
        new Date(this.bookingData.bookingDateTime).getMonth() + 1
      )
    );
  }

  onPreDate() {
    this.bookingData.bookingDateTime = new Date(
      new Date(this.bookingData.bookingDateTime).setMonth(
        new Date(this.bookingData.bookingDateTime).getMonth() - 1
      )
    );
  }

  onNextClick() {
    if (!this.bookingData.bookingDateTime) {
      return;
    }
    this.onNextClickChange.emit();
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }

  onDateChange(event: string | Date) {
    this.dateChange.emit(event);
  }
}
