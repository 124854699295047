import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {BookingWidgetComponent} from './booking-widget/booking-widget.component';
import {ConfirmBookingPageComponent} from './confirm-booking-page/confirm-booking-page.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {PaymentSummaryDiaryComponent} from './payment-summary-diary/payment-summary-diary.component';
import {VenueListComponent} from "./venue-list/venue-list.component";
import {TakeawayComponent} from "./takeaway/takeaway.component";

const routes: Routes = [
  {
    path: 'widgets/online-widget/all-venue/:venueId',
    component: VenueListComponent
  },
  {
    path: 'online-order/:venueId',
    component: TakeawayComponent
  },
  {
    path: 'online-booking/:venueId',
    component: BookingWidgetComponent
  },
  {
    path: 'confirm-booking',
    component: ConfirmBookingPageComponent
  },
  {
    path: '',
    component: ErrorPageComponent
  },
  {
    path: 'booking-payment',
    component: PaymentSummaryDiaryComponent
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRouting{
}
