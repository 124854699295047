/* tslint:disable:align */
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {WidgetService} from '../../../services/widget.service';
import {NotificationService, NotificationStatus} from '../../../services/notification.service';
import {DateTimezoneHelper} from '../../../utils/helper/date-timezone.helper';
import {BookingData} from '../../../utils/model/bookingData';
import * as moment from 'moment';
import {BookingWidgetModel} from '../../../utils/model/booking-widget.model';

@Component({
  selector: 'booking-selector',
  templateUrl: './booking-selector.component.html',
  styleUrls: ['./booking-selector.component.scss']
})
export class BookingSelectorComponent implements OnInit {
  @Input() bookingData: BookingData;
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  bookingList = [];
  bookingOptionTotalQuantity = 0;
  constructor(
    private widgetService: WidgetService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {

  }
  increasePeople(index, i?, ind?, type?) {
    if(this.bookingOptionTotalQuantity > this.bookingData.people) {
      return;
    }
    this.bookingData.bookingOptions[index].bookingList  = JSON.parse(JSON.stringify(this.bookingData.bookingOptions[index].bookingList))
    if(type === 'child'){
      if(!this.bookingData.bookingOptions[index].applyForAll){
        this.bookingData.bookingOptions[index].bookingList[i][ind].quantity++;
        this.bookingData.bookingOptions[index].childBookingOption[ind].quantityPerPerson[i]++;
      }
      else{
        this.bookingData.bookingOptions[index].bookingList[0][ind].quantity++;
        this.bookingData.bookingOptions[index].childBookingOption[ind].quantity++;
      }
    }else{
      this.bookingData.bookingOptions[index].quantity++;
      this.bookingOptionTotalQuantity++;
      this.bookingData.bookingOptions[index].totalPrice = this.bookingData.bookingOptions[index].quantity * this.bookingData.bookingOptions[index].pricePerPerson
      if(this.bookingData.bookingOptions[index].applyForAll) {
        this.bookingData.bookingOptions[index].childBookingOption.forEach((child , i) => {
          child.quantity = this.bookingData.bookingOptions[index].quantity;
          this.bookingData.bookingOptions[index].bookingList[0][i].quantity = this.bookingData.bookingOptions[index].quantity;
        })
      }
    }
  }

  decreasePeople(index, i?, ind?,  type?) {
    if(this.bookingOptionTotalQuantity <= 0) {
      return;
    }
    this.bookingData.bookingOptions[index].bookingList  = JSON.parse(JSON.stringify(this.bookingData.bookingOptions[index].bookingList))
    if(type === 'child') {
      if(!this.bookingData.bookingOptions[index].applyForAll && this.bookingData.bookingOptions[index].childBookingOption[ind].quantityPerPerson[i] > 0){
        this.bookingData.bookingOptions[index].bookingList[i][ind].quantity--;
        this.bookingData.bookingOptions[index].childBookingOption[ind].quantityPerPerson[i]--;
      }
      if(this.bookingData.bookingOptions[index].applyForAll && this.bookingData.bookingOptions[index].childBookingOption[ind].quantity > 0){
        this.bookingData.bookingOptions[index].childBookingOption[ind].quantity--;
        this.bookingData.bookingOptions[index].bookingList[0][ind].quantity--;
      }
    }else{
      if (this.bookingData.bookingOptions[index].quantity > 0) {
        this.bookingData.bookingOptions[index].quantity--;
        this.bookingOptionTotalQuantity--;
        this.bookingData.bookingOptions[index].totalPrice = this.bookingData.bookingOptions[index].quantity * this.bookingData.bookingOptions[index].pricePerPerson
        if(this.bookingData.bookingOptions[index].applyForAll) {
          this.bookingData.bookingOptions[index].childBookingOption.forEach((child , i) => {
            child.quantity = this.bookingData.bookingOptions[index].quantity;
            this.bookingData.bookingOptions[index].bookingList[0][i].quantity = this.bookingData.bookingOptions[index].quantity;
          });
        }
      }
    }
  }
  onBackClick() {
    this.onBackClickChange.emit();
  }

  onNextClick() {
    this.bookingData.bookingOptions.forEach(bookingOption => {
      if(!bookingOption.applyForAll) {
        bookingOption.childBookingOption.forEach(child => {
          child.quantity = child.quantityPerPerson.reduce((a, b) => a + b);
        });
      }
      else{
        bookingOption.childBookingOption.forEach(child => {
          child.quantityPerPerson = [];
        });
      }
    });
    this.onNextClickChange.emit();
  }


  getPersonValue(event , index: number) {
    this.bookingData.bookingOptions[index].applyForAll = event;
    if(event){
      this.bookingData.bookingOptions[index].bookingList.splice(1, this.bookingData.people -1)
      this.bookingData.bookingOptions[index].bookingList[0].forEach((obj , i) => {
        obj.quantity = this.bookingData.bookingOptions[index].quantity;
        this.bookingData.bookingOptions[index].childBookingOption[i].quantity = this.bookingData.bookingOptions[index].quantity;
      })
    }else{
      let bookingData = this.bookingData.bookingOptions[index].bookingList[0];
      this.bookingData.bookingOptions[index].bookingList = [];
      for (let i = 0; i < this.bookingData.people; i++) {
        this.bookingData.bookingOptions[index].bookingList.push(bookingData);
        this.bookingData.bookingOptions[index].childBookingOption.forEach((cOption, chI) => {
          cOption.quantityPerPerson[i] = 1;
          this.bookingData.bookingOptions[index].bookingList[i][chI].quantity = cOption.quantityPerPerson[i];
        });
        this.bookingData.bookingOptions[index].bookingList = JSON.parse(JSON.stringify(this.bookingData.bookingOptions[index].bookingList));
      }
    }
  }

}
