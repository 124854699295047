<nb-layout>
  <nb-layout-column>
    <div *ngIf="!isLoader" class="bottom-main-div">
      <div class="header-div">
      </div>
      <div class="preview-content">
        <div class="logo-main">
          <div class="logo-image-div">
            <img [src]="venueLogo"/>
          </div>
          <div class="venue-name mt-20"></div>
        </div>
        <booking-summary [bookingData]="bookingData"
                         [customerData]="customerData"
                         [isFromEmail]="true"
                         class="w-100"></booking-summary>
      </div>
    </div>
    <div
      *ngIf="isLoader"
      [nbSpinner]="isLoader"
      class="status-primary"
      nbSpinnerSize="giant"
      nbSpinnerStatus="primary"
      style="height: 100%; width: 100%; z-index: 999999; position: absolute"
    ></div>
  </nb-layout-column>
</nb-layout>
