import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Urls} from '../utils/urls';

@Injectable()
export class ConfirmBookingService {
  constructor(private http: HttpClient) {
  }

  confirmBooking(token) {
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.bookings + '/online-widget/confirm-booking?token=' + token , {})
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  cancleBooking(token) {
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.bookings + '/online-widget/cancel-booking?token=' + token , {})
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
}
