import {Component, OnInit} from '@angular/core';
import {PaymentConfirmService} from '../../services/payment-confirm-service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../services/notification.service';
import {WidgetService} from '../../services/widget.service';
import * as moment from 'moment';
import {BookingWidgetModel} from "../../utils/model/booking-widget.model";
@Component({
  selector: 'app-payment-summary-diary',
  templateUrl: './payment-summary-diary.component.html',
  styleUrls: ['./payment-summary-diary.component.scss']
})
export class PaymentSummaryDiaryComponent implements OnInit {

  token;
  bookingData;
  isLoader = false;
  customerData;
  venueLogo;
  constructor(private paymentService: PaymentConfirmService,
              private activeRoute: ActivatedRoute,
              private notificationService: NotificationService,
              private widgetService: WidgetService) {
  }

  ngOnInit(): void {
    this.activeRoute.queryParamMap.subscribe((data: any) => {
      this.token = data.params.token;
      this.getBookingDetails();
    });
  }

  getBookingDetails() {
    this.isLoader = true;
    this.paymentService.getBookingDetails(this.token)
      .then((res: any) => {
        this.setBookingData(res);
        this.isLoader = false;
        this.customerData = res.customer;
        this.widgetService.setSelectedService(res.service ? res.service : res.event);
        this.widgetService.setSelectedSection({sectionName: res.tables[0].section.name});
        this.widgetService.setVenue({venueName: res.venueName, _id: res.venueId , payment:{gateWayName : res.gateWayName}, timezone: this.bookingData.timezone});
        this.widgetService.getWidgetSettings(res.venueId)
          .then((res: any) => {
            this.venueLogo = res.data.widget.venueId.logo;
          });
        /* const root = document.documentElement;
         root.style
           .setProperty('--primaryColor', this.widgetSettings.widgetGenerator.primaryColour);
         root.style
           .setProperty('--secondaryColor', this.widgetSettings.widgetGenerator.secondaryColour);
         if (this.widgetSettings.widgetGenerator.font) {
           root.style
             .setProperty('--fontFamily', this.widgetSettings.widgetGenerator.font);
         } else {
           root.style
             .setProperty('--fontFamily', 'GoogleSans');
         }*/
      })
      .catch(err => {
        console.log(err);
      });
  }

  setBookingData(data) {
    this.bookingData = JSON.parse(JSON.stringify(data));
    this.bookingData.venueId = data.venueId._id;
    this.bookingData.customerId = data.customer._id;
    this.bookingData.serviceId = data?.service?._id;
    this.bookingData.eventId = data?.event?._id
    this.bookingData.sectionIds = data.tables[0].sectionId;
    this.bookingData.statusId = data.statusId;
  }
}
