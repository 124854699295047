<nb-layout>
  <nb-layout-column>
    <div *ngIf="widgetSettings" class="bottom-main-div">
      <div class="header-div"></div>
      <div class="preview-content" *ngIf="!isPaymentDone">
        <div class="logo-main">
          <div class="logo-image-div">
            <img [src]="widgetSettings.venueId.logo"/>
          </div>
          <div class="venue-name mt-20">{{widgetSettings.venueId.venueName}}</div>
        </div>
        <app-takeaway-date-selector [hidden]="bookingStep !== -1"
                                    (dateChange)="onDateChange($event)"
                                    (onNextClickChange)="onNextClick()"
                                    class="w-100"></app-takeaway-date-selector>
        <app-takeaway-service [serviceList]="serviceList"
                                [bookingData]="bookingData"
                                [hidden]="bookingStep !== 0"
                                (onNextClickChange)="onNextClick()"
                                (onBackClickChange)="onBackClick()"
                                class="w-100"></app-takeaway-service>
        <app-takeaway-menu [bookingData]="bookingData"
                           [hidden]="bookingStep !== 1"
                           [selectedIndex] = bookingStep
                           (onNextClickChange)="onNextClick()"
                           (onBackClickChange)="onBackClick()"
                           class="w-100"></app-takeaway-menu>
        <app-takeaway-customer [bookingData]="bookingData"
                               [hidden]="bookingStep !== 2"
                               (onNextClickChange)="onNextClick()"
                               (onBackClickChange)="onBackClick()"
                               class="w-100"></app-takeaway-customer>
        <app-takeaway-booking-summary [bookingData]="bookingData"
                                      *ngIf="bookingStep === 3"
                                      (stripeDataChange)="stripeDataChanged($event)"
                                      (onNextClickChange)="onNextClick()"
                                      (onBackClickChange)="onBackClick()"
                                      class="w-100"></app-takeaway-booking-summary>
      </div>
      <div *ngIf="isPaymentDone" class="preview-content message-div">
        <div class="thank-you-main-container">
          <div class="logo-image-div">
            <img [src]="widgetSettings.venueId.logo"/>
          </div>
          <p [innerHTML]="widgetSettings.widgetMessages.finalWidgetScreenMessage"></p>
          <p>please arrive at the given time to collect your order !!</p>
        </div>
      </div>
      <div *ngIf="!isOnlineBooking" class="preview-content message-div">
        <div class="thank-you-main-container">
          <div class="logo-image-div">
            <img [src]="widgetSettings.venueId.logo"/>
          </div>
          <p [innerHTML]="widgetSettings.widgetMessages.onlineBookingOff"></p>
        </div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
