import {Component, OnInit} from '@angular/core';
import {WidgetService} from '../services/widget.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'bookya-booking-widget';
}
