import {Component, OnInit} from '@angular/core';
import {WidgetService} from '../../services/widget.service';
import {BookingWidgetModel} from '../../utils/model/booking-widget.model';
import {ActivatedRoute} from '@angular/router';
import {NbThemeService} from '@nebular/theme';
import {DateTimezoneHelper} from '../../utils/helper/date-timezone.helper';
import {NotificationService, NotificationStatus} from '../../services/notification.service';
import {BookingData, BookingType, PaymentType} from '../../utils/model/bookingData';
import {Customer} from '../../utils/model/customer';
import {PaymentConfirmService} from "../../services/payment-confirm-service";
import * as moment from "moment";

@Component({
  selector: 'app-booking-widget',
  templateUrl: './booking-widget.component.html',
  styleUrls: ['./booking-widget.component.scss']
})
export class BookingWidgetComponent implements OnInit {
  date = new Date();
  isNext = false;
  widgetSettings: BookingWidgetModel;
  venueId;
  bookingStep = 0;
  serviceList = [];
  tempDate = '';
  bookingData: BookingData = {
    bookingType: BookingType.WIDGET,
    people: 0,
    timeslot: '',
    duration: '120',
    customerId: null,
    timezone: '',
    readyToShareTable: false,
    exitDateTime: null,
    bookingDateTime: new Date(),
    bookingNote: '',
    customerNote: '',
    managerNote: '',
    sectionIds: [],
    tables: [],
    serviceId: null,
    eventId: null,
    statusId: null,
    badges: [],
    payment: {type: PaymentType.NOPAYMENT, amount: '0'},
    guestList: [],
    bookingFor: '',
    bookingOptions : [
    ]
  };
  customerData: Customer = {
    name: '',
    email: '',
    contactNumber: '+61',
    alternateContactNumber: '',
    birthdate: new Date(),
    address: '',
    company: '',
    postcode: '',
    suburb: '',
    notes: {
      customerNotes: '',
      managerNotes: '',
    },
    isSubscribedToMailingList: false,
  };
  stripeData;
  isPaymentDone = false;
  isOnlineBooking = true;
  token;
  editData;
  selectedService;
  constructor(private widgetService: WidgetService,
              private activatedRoute: ActivatedRoute,
              private themeService: NbThemeService,
              private notificationService: NotificationService,
              private paymentService: PaymentConfirmService,) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((data: any) => {
      this.venueId = data.params.venueId;
      this.getWidgetSettings();
    });
    this.activatedRoute.queryParamMap.subscribe((data: any) => {
      this.isPaymentDone = data.params.payment;
      this.token = data.params.token;
    });
    if (this.activatedRoute.snapshot.queryParams.date) {
      const paramsDate = moment(
        new Date(this.activatedRoute.snapshot.queryParams.date).toISOString()
      );
      if (paramsDate.isBefore(moment.utc(moment.utc(new Date()).clone().format('YYYY-MM-DD'), 'YYYY-MM-DD').toISOString())) {
        this.bookingData.bookingDateTime = new Date();
      } else {
        this.bookingData.bookingDateTime = new Date(
          this.activatedRoute.snapshot.queryParams.date
        );
        if (this.activatedRoute.snapshot.queryParams.id) {
          this.bookingData.people = 2;
          this.bookingData.serviceId =
            this.activatedRoute.snapshot.queryParams.id;
          this.bookingStep++;
        }
      }
    }
    this.widgetService.paymentStatusChange.subscribe((data: boolean) => {
      this.isPaymentDone = data;
      this.bookingStep = -1;
    });

  }

  getBookingDetails() {
    this.paymentService.getBookingDetails(this.token)
      .then((res: any) => {
        this.editData = res;
        this.setEditData();
      })
      .catch(err => {
        console.log(err);
      });
  }
  setEditData(){
    this.bookingData = JSON.parse(JSON.stringify(this.editData));
    this.bookingData.bookingDateTime = new DateTimezoneHelper().convertUTCtoTimeZone(this.bookingData.bookingDateTime, '');
    this.selectedService = this.editData.service;
    this.bookingData.sectionIds = [];
    this.bookingData.sectionIds.push(this.editData.tables[0].section._id);
    this.customerData = this.editData.customer;
    this.bookingData.statusId = this.editData.status._id;
    this.bookingData.customerId = this.editData.customer._id;
    this.bookingData.serviceId = this.editData.service._id;
    this.bookingData.bookingOptions = [];
    this.bookingData.timeslot = moment(this.bookingData.bookingDateTime).format("HH:mm");
    this.getServiceByDate(true);
  }
  getWidgetSettings() {
    this.widgetService.getWidgetSettings(this.venueId)
      .then((res: any) => {
        this.widgetSettings = res.data.widget;
        this.isOnlineBooking = this.widgetSettings.widgetSettings.allowOnlineBooking;
        const root = document.documentElement;
        this.widgetService.setVenue(this.widgetSettings.venueId);
        this.widgetService.setWidgetSettings(this.widgetSettings);
        this.bookingData.timezone = this.widgetService.getVenue().timezone;
        root.style
          .setProperty('--primaryColor', this.widgetSettings.widgetGenerator.primaryColour);
        root.style
          .setProperty('--secondaryColor', this.widgetSettings.widgetGenerator.secondaryColour);
        if (this.widgetSettings.widgetGenerator.font) {
          root.style
            .setProperty('--fontFamily', this.widgetSettings.widgetGenerator.font);
        }
        if(this.token) {
          this.getBookingDetails();
        }
        else {
          this.getServiceByDate(true);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  onNextDate() {
    this.date = new Date(this.date.setDate(this.date.getDate() + 1));
  }

  onPreDate() {
    this.date = new Date(this.date.setDate(this.date.getDate() - 1));
  }

  onNextClick() {
    this.bookingStep++;
  }

  onBackClick() {
    this.bookingStep--;
  }

  onHomeClick() {
    this.bookingStep=0;
  }

  getServiceByDate(init?) {
    const changeTime = new Date(this.bookingData.bookingDateTime).setHours(0, 0, 0, 0);
    this.tempDate = new DateTimezoneHelper().convertDateWithTimezone(changeTime, this.widgetService.getVenue().timezone);
    this.widgetService
      .getServiceByDate(this.tempDate, this.widgetService.getVenue().timezone , this.bookingData.bookingType)
      .then((res: any) => {
        this.serviceList = [];
        res.data.forEach(obj => {
          if(this.bookingData.serviceId &&  this.bookingData.serviceId === obj._id){
            obj.isSelected = false;
          }
          console.log(obj)
          if(obj.type === "Services"){
            if(obj.isOnline) {
              this.serviceList.push(obj);
            }
          }
          else{
            this.serviceList.push(obj);
          }
        });
        this.serviceList = JSON.parse(JSON.stringify(this.serviceList));

        if(this.serviceList.length < 1) {
          this.isNext = false;
          this.notificationService.showNotification(NotificationStatus.ERROR, this.widgetSettings.widgetMessages.dayClosed);
        } else{
          this.isNext = true;
        }
      })
      .catch((err) => {
        if (this.activatedRoute.snapshot.queryParams.id){
          this.bookingStep = 0;
          this.bookingData.bookingDateTime = new Date();
        }
        if (!init){
          this.isNext = false;
          this.notificationService.showNotification(NotificationStatus.ERROR, err.error.message ? err.error.message : 'Something went wrong.');
        }
      });
  }

  stripeDataChanged(event: any) {
    this.stripeData = event;
  }

  dateChange(event){
    this.bookingData.bookingDateTime = event;
    this.bookingData.serviceId = null;
    this.bookingData.sectionIds = [];
    this.bookingData.bookingOptions = [];
    this.bookingData.timeslot = '';
    this.getServiceByDate();
  }
}
