import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ConfirmBookingService} from '../../services/confirm-booking.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService, NotificationStatus} from '../../services/notification.service';
import {PaymentConfirmService} from "../../services/payment-confirm-service";
import {WidgetService} from "../../services/widget.service";

@Component({
  selector: 'app-confirm-booking-page',
  templateUrl: './confirm-booking-page.component.html',
  styleUrls: ['./confirm-booking-page.component.scss']
})
export class ConfirmBookingPageComponent implements OnInit {
  token;
  isStatusUpdated = false;
  venueLogo;
  editData;
  isEdit;
  venueName;
  status = null;
  allowEdit = false;
  allowCancel = false;
  @Output() editEvent = new EventEmitter();
  constructor(private confirmService: ConfirmBookingService,
              private activeRoute: ActivatedRoute,
              private paymentService: PaymentConfirmService,
              private notificationService: NotificationService,
              private widgetService: WidgetService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.activeRoute.queryParamMap.subscribe((data: any) => {
      this.token = data.params.token;
      this.getBookingDetails();
    });
  }

  getBookingDetails() {
    this.paymentService.getBookingDetails(this.token)
      .then((res: any) => {
        this.editData = res;
        this.widgetService.getWidgetSettings(res.venueId)
          .then((res: any) => {
            this.allowEdit = res.data.allowEdit;
            this.allowCancel = res.data.allowCancel;
            this.venueLogo = res.data.widget.venueId.logo;
            this.venueName = res.data.widget.venueId.venueName;
          });
      })
      .catch(err => {
        console.log(err);
      });
  }

  updateBooking(status) {
    const data = {
      status
    };
    if(status === 'edit'){
      this.isEdit = true;
      this.router.navigate(['online-booking/'+ this.editData?.venueId] , { queryParams: { token: this.token } });
    }
    else {
      if (this.token) {
        if(status === 'Confirmed'){
          this.confirmService.confirmBooking(this.token)
            .then((res: any) => {
              this.isStatusUpdated = true;
              this.status = 'Confirmed';
              this.notificationService.showNotification(NotificationStatus.SUCCESS, res.message);
            })
            .catch((err: any) => {
              this.isStatusUpdated = false;
              this.notificationService.showNotification(NotificationStatus.ERROR, err.error.message);
            });
        }
        else{
          this.confirmService.cancleBooking(this.token)
            .then((res: any) => {
              this.isStatusUpdated = true;
              this.status = 'Cancelled';
              this.notificationService.showNotification(NotificationStatus.SUCCESS, res.message);
            })
            .catch((err: any) => {
              this.isStatusUpdated = false;
              this.notificationService.showNotification(NotificationStatus.ERROR, err.error.message);
            });
        }

      } else {
        this.notificationService.showNotification(NotificationStatus.ERROR, 'Invalid token.');
      }
    }
  }
}
