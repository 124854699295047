<div class="w-100 d-flex just-center flex-dir p-20">
  <div class="main-content-menu-selector w-100">
    <div class="row just-center">
        <ng-container *ngFor="let menu of menuList;let  ind = index">
          <div class="col-6 mt-20">
            <div class="menu-card">
              <div class="d-flex just-between">
                <div class="w-60 mr-10"><span class="font-medium menu-header">{{menu.name}} - ${{menu.price}}</span></div>
                <div class="quantity_div d-flex just-end">
                  <div class="icon-div">
                    <nb-icon (click)="decreasePeople(ind); $event.stopPropagation()" class="mr-5 cursor-pointer icon"
                             icon="minus-circle-outline"></nb-icon>
                  </div>
                  <input type="number" class="cards__number" placeholder="00" [(ngModel)]="menu.quantity" readonly>
                  <div class="icon-div">
                    <nb-icon (click)="increasePeople(ind); $event.stopPropagation()" class="ml-5 cursor-pointer icon"
                           icon="plus-circle-outline"></nb-icon>
                  </div>
                </div>
              </div>
              <p class="desc" [innerHTML]="menu.description"></p>
            </div>
          </div>
        </ng-container>
    </div>
  </div>
  <div class="d-flex just-end pt-10">
    <button (click)="onBackClick()" class="calendar-button mr-10" nbButton status="basic">Back</button>
    <button (click)="onNextClick()" class="calendar-button" nbButton status="success">Next</button>
  </div>
</div>

