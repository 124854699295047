import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NotificationService, NotificationStatus} from "../../../services/notification.service";
import {WidgetService} from "../../../services/widget.service";

@Component({
  selector: 'app-takeaway-menu',
  templateUrl: './takeaway-menu.component.html',
  styleUrls: ['./takeaway-menu.component.scss']
})
export class TakeawayMenuComponent implements OnInit , OnChanges {
  menuList;
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Input() bookingData;
  @Input() selectedIndex;
  constructor(private widgetService: WidgetService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.selectedIndex && changes.selectedIndex.currentValue === 1){
      this.menuList = this.widgetService.getSelectedService().menu;
      this.menuList.forEach(obj => {
        obj.quantity = obj.quantity ? obj.quantity : 0;
      });
    }
  }

  onNextClick() {
    this.bookingData.booking.menu = [];
    this.menuList.forEach(obj => {
      if(obj.quantity > 0){
        this.bookingData.booking.menu.push({
          menuId: obj._id,
          quantity: obj.quantity,
          name: obj.name,
          price: obj.price
        });
      }
    });
    if(this.bookingData.booking.menu.length === 0){
      this.notificationService.showNotification(NotificationStatus.ERROR, "Please selecte menu");
      return;
    }
    this.onNextClickChange.emit();
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }

  decreasePeople(ind) {
    if(this.menuList[ind].quantity > 0){
      this.menuList[ind].quantity--;
    }
    return;
  }

  increasePeople(ind){
    this.menuList[ind].quantity++;
  }
}
