import {BookingData} from '../model/bookingData';
import {Customer} from '../model/customer';


export class BookingValidationHelper {
  mailRegx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  validateBookings(bookingData: BookingData) {
    if (!bookingData.bookingDateTime) {
      return {error: 'Please select booking date', errStatus: true};
    }
    if (!bookingData.duration) {
      return {error: 'Please enter duration', errStatus: true};
    }
    if (!bookingData.people) {
      return {error: 'Please enter number of people.', errStatus: true};
    }
    if (!bookingData.serviceId) {
      return {error: 'Please select service.', errStatus: true};
    }
    if (!bookingData.customerId) {
      return {error: 'Please enter customer details', errStatus: true};
    }
    // todo: need to uncomment when guestlist is required
    /*if (bookingData.guestList.length) {
      for (let i =0 ; i<bookingData.guestList.length; i++) {
        let guest = bookingData.guestList[i];
        if (!guest.name.trim().length) {
          return {error:"Please enter valid name for guest " + (i + 1), errStatus:true};
        }
        if (!guest.contactNumber.length) {
          return {error:"Please enter contact number for guest " + (i + 1), errStatus:true};
        }
        if (!this.checkPhoneNumber(guest.contactNumber)) {
          return {error:"Please enter valid contact number for guest " + (i + 1), errStatus:true};
        }
      }
    }*/
  }

  customerValidation(customerData: Customer) {
    if (!customerData.name || !customerData.name.trim().length) {
      return {error: 'Please enter customer name.', errStatus: true};
    }
    if (!customerData.email || !customerData.email.trim().length) {
      return {error: 'Please enter customer email.', errStatus: true};
    }
    if (customerData.email && !this.mailRegx.test(customerData.email)) {
      return {error: 'Please enter valid email.', errStatus: true};
    }
    if (!this.checkPhoneNumber(customerData.contactNumber)) {
      return {error: 'Please enter valid contact number.', errStatus: true};
    }
  }

  notesValidation(noteData) {
    if (!noteData.bookingNote &&
      !noteData.bookingNote.trim().length) {
      return {error: 'Please enter valid booking note.', errStatus: true};
    }
    if (!noteData.customerNote &&
      !noteData.customerNote.trim().length) {
      return {error: 'Please enter valid customer note.', errStatus: true};
    }
    if (!noteData.managerNote &&
      !noteData.managerNote.trim().length) {
      return {error: 'Please enter valid manager note.', errStatus: true};
    }
  }

  bookingTabValidation(bookingData) {
    if (!bookingData.bookingDateTime) {
      return {error: 'Please select booking date', errStatus: true};
    }
    if (!bookingData.duration) {
      return {error: 'Please enter duration', errStatus: true};
    }
    if (!bookingData.people) {
      return {error: 'Please enter number of people.', errStatus: true};
    }
    if (!bookingData.serviceId) {
      return {error: 'Please select service.', errStatus: true};
    }
    if (!bookingData.statusId) {
      return {error: 'Please select a booking status', errStatus: true};
    }
  }

  checkPhoneNumber(data) {
    return this.isValidPhone(data.split('-')[1]);
  }

  isValidPhone(userInput) {
    if (userInput.length <= 10 && userInput.length > 6) {
      const regex = /^[0-9]*$/;
      return regex.test(userInput);
    } else {
      return false;
    }
  }
}
