import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationStatus} from "../../../services/notification.service";

@Component({
  selector: 'app-takeaway-date-selector',
  templateUrl: './takeaway-date-selector.component.html',
  styleUrls: ['./takeaway-date-selector.component.scss']
})
export class TakeawayDateSelectorComponent implements OnInit {
  bookingDateTime;
  lastDate;
  @Output() dateChange = new EventEmitter();
  @Output() onNextClickChange = new EventEmitter();
  constructor() {
  }

  ngOnInit(): void {
    this.lastDate =  new Date (new Date().setDate(new Date().getDate() - 1))
  }

  onDateChange(event: string | Date) {
    this.dateChange.emit(event);
  }

  onNextClick(){
    this.onNextClickChange.emit();
  }

}
