import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {NbPopoverModule, NbThemeModule} from '@nebular/theme';
import {CommonModule} from '@angular/common';
import {NebularModule} from './nebular.module';
import {HttpClientModule} from '@angular/common/http';
import {WidgetService} from '../services/widget.service';
import {AppRouting} from './app.routing';
import {BookingWidgetComponent} from './booking-widget/booking-widget.component';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {DateSelectorComponent} from './booking-widget/date-selector/date-selector.component';
import {ServiceSelectorComponent} from './booking-widget/service-selector/service-selector.component';
import {CustomeDetailsComponent} from './booking-widget/custome-details/custome-details.component';
import {BookingSummaryComponent} from './booking-widget/booking-summary/booking-summary.component';
import {NotificationService} from '../services/notification.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PhoneInputComponent} from './booking-widget/phone-input/phone-input.component';
import {CountriesService} from '../services/countries.service';
import {NgxStripeModule} from 'ngx-stripe';
import {ConfirmBookingPageComponent} from './confirm-booking-page/confirm-booking-page.component';
import {ConfirmBookingService} from '../services/confirm-booking.service';
import {ErrorPageComponent} from './error-page/error-page.component';
import {PaymentSummaryDiaryComponent} from './payment-summary-diary/payment-summary-diary.component';
import {PaymentConfirmService} from '../services/payment-confirm-service';
import { BookingSelectorComponent } from './booking-widget/select-booking/booking-selector.component';
import {VenueListComponent} from "./venue-list/venue-list.component";
import {VenueService} from "../services/venue-list.service";
import { TakeawayComponent } from './takeaway/takeaway.component';
import {TakeawayServiceComponent} from "./takeaway/takeaway-service/takeaway-service.component";
import {TakeawayDateSelectorComponent} from "./takeaway/takeaway-date-selector/takeaway-date-selector.component";
import {TakeawayMenuComponent} from "./takeaway/takeaway-menu/takeaway-menu.component";
import {TakeawayCustomerComponent} from "./takeaway/takeaway-customer/takeaway-customer.component";
import {TakeawayBookingSummaryComponent} from "./takeaway/takeaway-booking-summary/takeaway-booking-summary.component";
import {TimeFormatPipe} from "./booking-widget/service-selector/timeformat.pipe";

@NgModule({
  declarations: [
    AppComponent,
    BookingWidgetComponent,
    DateSelectorComponent,
    ServiceSelectorComponent,
    CustomeDetailsComponent,
    BookingSummaryComponent,
    PhoneInputComponent,
    ConfirmBookingPageComponent,
    ErrorPageComponent,
    PaymentSummaryDiaryComponent,
    BookingSelectorComponent,
    VenueListComponent,
    TakeawayComponent,
    TakeawayServiceComponent,
    TakeawayDateSelectorComponent,
    TakeawayMenuComponent,
    TakeawayCustomerComponent,
    TakeawayBookingSummaryComponent,
    TimeFormatPipe,
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NbThemeModule.forRoot({name: 'corporate'}),
        AppRouting,
        CommonModule,
        NebularModule,
        NbEvaIconsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxStripeModule.forRoot('pk_test_51Hr0oLFX8qUmglf3tBevxC1bP9GS1tlicb5ZFRRNTYHOsZZD9MbxlC6F96ccfM1FWYb3n2FWrJ9YpLsrptO9Nzl700mtMkSa4v'),
        NbPopoverModule
    ],
  providers: [WidgetService, NotificationService, CountriesService, ConfirmBookingService, PaymentConfirmService, VenueService],
  bootstrap: [AppComponent]
})
export class AppModule { }
