<div class="w-100 d-flex flex-dir just-center p-20">
  <div class="main-content-take-away w-100">
    <div class="mt-20 w-100">
      <div class="d-flex f-wrap col-gap-16 w-100">
        <div class="w-100 service-div">
          <div class="d-flex just-end">
            <button (click)="onBackClick()" class="calendar-button" nbButton status="success">Book Another Day</button>
          </div>
          <div class="common-title">Services</div>
          <div class="d-flex f-wrap gap-5">

            <div (click)="onServiceSelected(service, si)" *ngFor="let service of serviceList; let si = index"
                 [ngClass]="{'selected': service.isSelected}" class="common-chips mt-10">
              {{service.name}}
              <div *ngIf="service.event">
                {{service.event?.eventName + " "}}Event
              </div>
            </div>
          </div>

        </div>
      </div>
      <div *ngIf="timeSlotList.length" class="common-title timslot-loader mt-20">Time</div>
      <div class="d-flex col-gap-16 w-100" [nbSpinner]="isLoading" nbSpinnerStatus="success">
        <div class="d-flex f-wrap gap-5">
          <div (click)="onTimeSlotSelected(timeslot, ti)" *ngFor="let timeslot of timeSlotList; let ti = index"
               [ngClass]="{'selected': timeslot.isSelected}" class="common-chips mt-10">
            {{timeslot.timeSlot}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex just-end pt-10">
    <button (click)="onNextClick()" class="calendar-button" nbButton status="success">Next</button>
  </div>
</div>
<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card class="policy_aggrement">
    <nb-card-header>Policy Agreement</nb-card-header>
    <nb-card-body class="container__scroll">
      <ng-container *ngIf="currentSelectedService.type==='Services'" >
        <span [innerHTML]="currentSelectedService.policyAgreement"></span>
      </ng-container>
      <ng-container *ngIf="currentSelectedService.type==='Event'" >
        <span [innerHTML]="currentSelectedService.settings.policyAgreementNote"></span>
      </ng-container>
    </nb-card-body>
    <nb-card-footer>
      <nb-checkbox (checkedChange)="onPolicyAgreement($event)"  status="primary">I agree to the policy Agreement</nb-checkbox>
    </nb-card-footer>
  </nb-card>
</ng-template>
