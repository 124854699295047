import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BookingData } from '../../../utils/model/bookingData';
import { WidgetService } from '../../../services/widget.service';
import { Customer } from '../../../utils/model/customer';
import {
  NotificationService,
  NotificationStatus,
} from '../../../services/notification.service';
import { DateTimezoneHelper } from '../../../utils/helper/date-timezone.helper';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import {
  StripeCardComponent,
  StripeFactoryService,
  StripeInstance,
} from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss'],
})
export class BookingSummaryComponent implements OnInit, OnChanges {
  @Input() bookingData: BookingData;
  @Input() token;
  @Input() customerData: Customer;
  @Input() isFromEmail = false;
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Output() onHomeClickChange = new EventEmitter();
  @Output() stripeDataChange = new EventEmitter();
  selectedService;
  selectedSection;
  selectedVenue;
  stripeKey;
  badges = '';
  isLoading = false;
  isLoaded;
  @ViewChild('inActiveDialog') inActiveDialog;
  @ViewChild('dialog') cardDialog;
  stripe: StripeInstance;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#66737f',
        color: '#66737f',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '0.9375rem',
        fontSmoothing: 'antialiased',
        padding: '10px',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#b8c1cc',
        },
      },
      invalid: {
        iconColor: '#fe5050',
        color: '#fe5050',
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
      },
    ],
  };
  nameOnCard = '';
  bookingDate;
  cancelToken;
  widgetSettings;
  totalAmount;
  paidAmount;
  discountAmount;
  total;
  date;
  isBooked = false;
  inActiveTimeOut;
  constructor(
    private widgetService: WidgetService,
    private notificationService: NotificationService,
    private dialogService: NbDialogService,
    private stripeFactory: StripeFactoryService,
    @Optional() private dialogRef: NbDialogRef<any>,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.selectedSection = this.widgetService.getSelectedSection();
    this.selectedService = this.widgetService.getSelectedService();
    this.selectedVenue = this.widgetService.getVenue().venueName;
    this.widgetSettings = this.widgetService.getWidgetSettingsFromLocal();
    this.bookingData.bookingFor = this.widgetService.getBookingType();
    if (this.token) {
      this.bookingData.statusId = null;
    }
    if (!this.bookingData.statusId) {
      const bookingData = JSON.parse(JSON.stringify(this.bookingData));
      bookingData.bookingOptions = [];
      this.bookingData.bookingOptions.forEach((bookiOption) => {
        if (bookiOption.quantity > 0) {
          bookingData.bookingOptions.push(bookiOption);
        }
      });
      this.widgetService
        .paymentSummary(bookingData)
        .then((res: any) => {
          this.total = res.data.totalAmount;
          this.discountAmount = res.data.discountAmount;
          this.paidAmount = res.data.paidAmount;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bookingData) {
      const tempBadges = [];
      // DATE CONVERSION FOR PAYMENT LINK
      if (this.route.url.includes("booking-payment")) {
        this.bookingDate = new DateTimezoneHelper().convertUTCtoTimeZone(this.bookingData.bookingDateTime, this.widgetService.getVenue().timezone);
      } else {
        this.bookingDate = new DateTimezoneHelper().convertUTCtoTimeZone(
          new DateTimezoneHelper().convertDateWithTimezone(
            this.bookingData.bookingDateTime,
            this.widgetService.getVenue().timezone
          ),
          this.widgetService.getVenue().timezone
        );
      }

      this.bookingData.badges.forEach((badge) => {
        tempBadges.push(badge.name);
      });
      this.badges = tempBadges.toString();
    }
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }

  async onPayment() {
    this.isLoading = true;
    this.isBooked = true;
    const bookingData = JSON.parse(JSON.stringify(this.bookingData));
    bookingData.bookingDateTime = moment(bookingData.bookingDateTime)
      .clone()
      .tz(this.widgetService.getVenue().timezone, true)
      .utc()
      .toISOString();
    bookingData.exitDateTime = moment(bookingData.exitDateTime)
      .clone()
      .tz(this.widgetService.getVenue().timezone, true)
      .utc()
      .toISOString();
    bookingData.bookingOptions = [];
    this.bookingData.bookingOptions.forEach((bookiOption) => {
      if (bookiOption.quantity > 0) {
        bookingData.bookingOptions.push(bookiOption);
      }
    });
    const customerError = await this.updateCustomer();
    if (customerError) {
      return;
    }
    if (!bookingData.statusId) {
      if (
        (this.widgetSettings.widgetSettings.maxPeopleAllowedOnline > 0 &&
          parseInt(bookingData.people.toString()) >
            this.widgetSettings.widgetSettings.maxPeopleAllowedOnline) ||
        this.paidAmount === 0
      ) {
        bookingData.bookingFor = this.widgetService.getBookingType();

        if (this.token) {
          this.widgetService
            .updateBooking(bookingData, this.token)
            .then((res: any) => {
              this.isLoading = false;
              this.notificationService.showNotification(
                NotificationStatus.SUCCESS,
                res.message ? res.message : 'Booking added successfully.'
              );
              this.widgetService.paymentStatusChange.next(true);
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        } else {
          this.widgetService
            .addBooking(bookingData)
            .then((res: any) => {
              this.isLoading = false;
              this.notificationService.showNotification(
                NotificationStatus.SUCCESS,
                res.message ? res.message : 'Booking added successfully.'
              );
              this.widgetService.paymentStatusChange.next(true);
            })
            .catch((err) => {
              this.isLoading = false;
              this.notificationService.showNotification(
                NotificationStatus.ERROR,
                err.error.message ? err.error.message : 'Something went wrong'
              );
            });
        }
      } else {
        if (this.selectedService.paymentType !== 'no-payment') {
          if (this.widgetService.getVenue().payment.gateWayName === 'stripe') {
            const data = {
              bookingId: bookingData._id,
            };
            bookingData.bookingFor = this.widgetService.getBookingType();
            if (this.token) {
              this.widgetService
                .updateBooking(bookingData, this.token)
                .then((res: any) => {
                  this.isLoading = false;
                  this.totalAmount = res.amount;
                  this.stripeKey = res.key;
                  this.stripe = this.stripeFactory.create(this.stripeKey);
                  this.stripeDataChange.emit({
                    venue: this.selectedVenue,
                    amount: this.totalAmount,
                    key: this.stripeKey,
                  });
                  this.open();
                  this.widgetService.paymentData = res.bookingId;
                })
                .catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                  this.notificationService.showNotification(
                    NotificationStatus.ERROR,
                    err.error.message
                      ? err.error.message
                      : 'Something went wrong'
                  );
                });
            } else {
              this.widgetService
                .getStripeKey(
                  this.isFromEmail ? data : bookingData,
                  this.isFromEmail
                )
                .then((res: any) => {
                  this.isLoading = false;
                  this.totalAmount = res.amount;
                  this.stripeKey = res.key;
                  this.stripe = this.stripeFactory.create(this.stripeKey);
                  this.stripeDataChange.emit({
                    venue: this.selectedVenue,
                    amount: this.totalAmount,
                    key: this.stripeKey,
                  });
                  this.open();
                  this.widgetService.paymentData = res.bookingId;
                })
                .catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                  this.notificationService.showNotification(
                    NotificationStatus.ERROR,
                    err.error.message
                      ? err.error.message
                      : 'Something went wrong'
                  );
                });
            }
          } else {
            const data = {
              bookingId: bookingData._id,
            };
            bookingData.bookingFor = this.widgetService.getBookingType();
            if (this.token) {
              this.widgetService
                .updateBooking(bookingData, this.token)
                .then((res: any) => {
                  this.isLoading = false;
                  window.open(res.url, '_blank');
                  this.notificationService.showNotification(
                    NotificationStatus.SUCCESS,
                    res.message ? res.message : 'Booking added successfully.'
                  );
                })
                .catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                });
            } else {
              this.widgetService
                .getEwayUrl(
                  this.isFromEmail ? data : bookingData,
                  this.isFromEmail
                )
                .then((res: any) => {
                  this.isLoading = false;
                  window.open(res.url, '_blank');
                  this.notificationService.showNotification(
                    NotificationStatus.SUCCESS,
                    res.message ? res.message : 'Booking added successfully.'
                  );
                })
                .catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                  this.notificationService.showNotification(
                    NotificationStatus.ERROR,
                    err.error.message
                      ? err.error.message
                      : 'Something went wrong'
                  );
                });
            }
          }
        } else {
          bookingData.bookingFor = this.widgetService.getBookingType();
          if (this.token) {
            this.widgetService
              .updateBooking(bookingData, this.token)
              .then((res: any) => {
                this.isLoading = false;
                this.notificationService.showNotification(
                  NotificationStatus.SUCCESS,
                  res.message ? res.message : 'Booking added successfully.'
                );
                this.widgetService.paymentStatusChange.next(true);
              })
              .catch((err) => {
                this.isLoading = false;
                console.log(err);
              });
          } else {
            this.widgetService
              .addBooking(bookingData)
              .then((res: any) => {
                this.isLoading = false;
                this.notificationService.showNotification(
                  NotificationStatus.SUCCESS,
                  res.message ? res.message : 'Booking added successfully.'
                );
                this.widgetService.paymentStatusChange.next(true);
              })
              .catch((err) => {
                this.isLoading = false;
                console.log(bookingData);
                this.notificationService.showNotification(
                  NotificationStatus.ERROR,
                  err.error.message ? err.error.message : 'Something went wrong'
                );
              });
          }
        }
      }
    } else {
      if (this.widgetService.getVenue().payment.gateWayName === 'stripe') {
        const data = {
          bookingId: bookingData._id,
        };
        bookingData.bookingFor = this.widgetService.getBookingType();
        this.widgetService
          .getStripeKey(this.isFromEmail ? data : bookingData, this.isFromEmail)
          .then((res: any) => {
            this.isLoading = false;
            this.totalAmount = res.amount;
            this.stripeKey = res.key;
            this.stripe = this.stripeFactory.create(this.stripeKey);
            this.stripeDataChange.emit({
              venue: this.selectedVenue,
              amount: this.totalAmount,
              key: this.stripeKey,
            });
            this.open();
            this.widgetService.paymentData = res.bookingId;
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
            this.notificationService.showNotification(
              NotificationStatus.ERROR,
              err.error.message ? err.error.message : 'Something went wrong'
            );
          });
      } else {
        const data = {
          bookingId: bookingData._id,
        };
        bookingData.bookingFor = this.widgetService.getBookingType();
        this.widgetService
          .getEwayUrl(this.isFromEmail ? data : bookingData, this.isFromEmail)
          .then((res: any) => {
            this.isLoading = false;
            window.open(res.url, '_blank');
            this.notificationService.showNotification(
              NotificationStatus.SUCCESS,
              res.message ? res.message : 'Booking added successfully.'
            );
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
            this.notificationService.showNotification(
              NotificationStatus.ERROR,
              err.error.message ? err.error.message : 'Something went wrong'
            );
          });
      }
    }
  }

  open() {
    this.isLoaded = true;
    this.dialogRef = this.dialogService.open(this.cardDialog, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
    this.inActiveDialogOpenOrClose();
  }

  inActiveDialogOpenOrClose() {
    this.inActiveTimeOut = setTimeout(() => {
      this.closeDialog();
      this.dialogRef = this.dialogService.open(this.inActiveDialog, {
        closeOnBackdropClick: false,
        closeOnEsc: false,
      });
    }, 30 * 60 * 1000);
  }

  async updateCustomer() {
    this.customerData.notes.customerNotes = this.bookingData.customerNote;
    this.customerData.notes.managerNotes = this.bookingData.managerNote;
    try {
      await this.widgetService.updateCustomer(
        this.customerData,
        this.bookingData.customerId
      );
      return false;
    } catch (err) {
      this.notificationService.showNotification(
        NotificationStatus.ERROR,
        err.error.message ? err.error.message : 'Something went wrong'
      );
      return true;
    }
  }

  createToken(): void {
    const name = this.nameOnCard;
    this.isLoaded = false;
    this.stripe.createToken(this.card.element, { name }).subscribe((result) => {
      if (result.token) {
        // Use the token
        this.stripePay(result.token.id);
        this.cancelToken = result.token.id;
      } else if (result.error) {
        // Error creating the token
        this.isLoaded = true;
        console.log(result.error.message);
        this.notificationService.showNotification(
          NotificationStatus.ERROR,
          result.error.message
        );
      }
    });
    clearTimeout(this.inActiveTimeOut);
  }

  stripePay(token) {
    const data = {
      bookingId: this.widgetService.paymentData,
      stripeToken: token,
    };
    this.widgetService
      .setPayment(data, this.isFromEmail)
      .then((res) => {
        this.closeDialog();
        if (this.isFromEmail) {
          this.route.navigate(
            ['online-booking', this.widgetService.getVenue()._id],
            { queryParams: { payment: true } }
          );
        } else {
          this.widgetService.paymentStatusChange.next(true);
        }
        this.isLoaded = true;
      })
      .catch((err) => {
        console.log(err);
        this.isLoaded = true;
        this.widgetService.paymentStatusChange.next(false);
      });
  }

  cancelBooking() {
    const data = {
      bookingId: this.widgetService.paymentData,
      stripeToken: this.cancelToken,
      cancelled: true,
    };
    this.widgetService
      .setPayment(data, this.isFromEmail)
      .then((res: any) => {})
      .catch((err) => {
        console.log(err);
      });
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  closeAndBack() {
    this.closeDialog();
    this.onHomeClickChange.emit();
  }
}
