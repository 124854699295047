import {NgModule} from '@angular/core';
import {
  NbButtonModule,
  NbCalendarModule,
  NbCardModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbSelectModule,
  NbSpinnerModule,
  NbToastrModule,
  NbAccordionModule,
  NbCheckboxModule
} from '@nebular/theme';

@NgModule({
  imports: [
    NbCalendarModule,
    NbLayoutModule,
    NbAccordionModule,
    NbButtonModule,
    NbIconModule,
    NbInputModule,
    NbFormFieldModule,
    NbSelectModule,
    NbToastrModule.forRoot(),
    NbCardModule,
    NbDialogModule.forChild(),
    NbSpinnerModule,
    NbCheckboxModule
  ],
  exports: [
    NbCalendarModule,
    NbLayoutModule,
    NbButtonModule,
    NbIconModule,
    NbInputModule,
    NbToastrModule,
    NbFormFieldModule,
    NbSelectModule,
    NbCardModule,
    NbDialogModule,
    NbAccordionModule,
    NbSpinnerModule,
    NbCheckboxModule
  ]
})
export class NebularModule{

}
