<div class="w-100 d-flex just-center">
  <div class="main-content-date-selector">
    <div class="mt-20 w-100">
      <nb-calendar (dateChange)="onDateChange($event)" [(date)]="bookingDateTime" [boundingMonth]="true"
                   [showNavigation]="true"
                   size="medium" [min]="lastDate">
      </nb-calendar>
    </div>
    <div class="d-flex just-end mt-20 next-button">
      <button (click)="onNextClick()" class="calendar-button" nbButton status="success">Next</button>
    </div>
  </div>
</div>
