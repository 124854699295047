import { Component, OnInit } from '@angular/core';
import {WidgetService} from "../../services/widget.service";
import {ActivatedRoute} from "@angular/router";
import {BookingWidgetModel} from "../../utils/model/booking-widget.model";
import {DateTimezoneHelper} from "../../utils/helper/date-timezone.helper";
import {NotificationService, NotificationStatus} from "../../services/notification.service";
import * as moment from 'moment';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-takeaway',
  templateUrl: './takeaway.component.html',
  styleUrls: ['./takeaway.component.scss']
})
export class TakeawayComponent implements OnInit {
  widgetSettings;
  serviceList = [];
  bookingStep = 0;
  stripeData;
  bookingData = {
    venueId: null,
    customer: {
      name: null,
      email: null,
      contactNumber: '+61-',
      address: null
    },
    booking: {
      menu: [],
      pickUpTime: new Date(),
      serviceId: null
    }
  };
  isPaymentDone = false;
  isOnlineBooking = true;
  constructor(private widgetService: WidgetService,
              private activatedRoute: ActivatedRoute,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((data: any) => {
      this.bookingData.venueId = data.params.venueId;
      this.getWidgetSettings();
      this.getServiceByDate();
    });
    this.activatedRoute.queryParamMap.subscribe((data: any) => {
      this.isPaymentDone = data.params.payment;
    });
    this.widgetService.paymentStatusChange.subscribe((data: boolean) => {
      this.isPaymentDone = data;
      // this.bookingStep = -1;
    });
  }

  getWidgetSettings() {
    this.widgetService.getWidgetSettings(this.bookingData.venueId)
      .then((res: any) => {
        this.widgetSettings = res.data.widget;
        this.isOnlineBooking = this.widgetSettings.widgetSettings.allowOnlineBooking;
        const root = document.documentElement;
        this.widgetService.setVenue(this.widgetSettings.venueId);
        this.widgetService.setWidgetSettings(this.widgetSettings);
        //this.bookingData.timezone = this.widgetService.getVenue().timezone;
        root.style
          .setProperty('--primaryColor', this.widgetSettings.widgetGenerator.primaryColour);
        root.style
          .setProperty('--secondaryColor', this.widgetSettings.widgetGenerator.secondaryColour);
        if (this.widgetSettings.widgetGenerator.font) {
          root.style
            .setProperty('--fontFamily', this.widgetSettings.widgetGenerator.font);
        }

      })
      .catch(err => {
        console.log(err);
      });
  }

  getServiceByDate() {
    let date = this.bookingData.booking.pickUpTime;
    const changeTime = new Date(date).setHours(0, 0, 0, 0);
    const tempDate = new DateTimezoneHelper().convertDateWithTimezone(changeTime, this.widgetService.getVenue().timezone);
    this.widgetService
      .getServiceByDate(tempDate, this.widgetService.getVenue().timezone)
      .then((res: any) => {
        this.serviceList = [];
        const {timezone} = this.widgetService.getVenue();
        const daysOfWeek = [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
        ];
        res.data.forEach(service => {
          if(service.hasOwnProperty('menu') && service.menu.length > 0){
            service['timeSlot'] = [];
            const bookingDay = daysOfWeek[moment.utc(this.bookingData.booking.pickUpTime).tz(this.widgetService.getVenue().timezone).day()];
            const {openTime, closeTime} = service.dayWiseSchedule.filter(daySchedule => daySchedule.day === bookingDay)[0];

            const serviceStartTime = moment(moment.utc(openTime).tz(timezone).format('HH:mm'), "HH:mm");
            const serviceEndTime = moment(moment.utc(closeTime).tz(timezone).format('HH:mm'), "HH:mm");

            while (serviceStartTime.isSameOrBefore(serviceEndTime)) {
              service['timeSlot'].push({
                timeSlot: serviceStartTime.clone().format('HH:mm'),
                isSelected: false,
              });

              serviceStartTime.add(service.slotInterval, 'minutes');
            }
            serviceStartTime.subtract(service.slotInterval, 'minutes');
            if (serviceStartTime.isBefore(serviceEndTime)) {
              service['timeSlot'].push({
                timeSlot: serviceEndTime.clone().format('HH:mm'),
                isSelected: false,
              });
            }
            this.serviceList.push(service);
          }
        })
      })
      .catch((err) => {
        console.log(err)
        this.notificationService.showNotification(NotificationStatus.ERROR, err.error.message ? err.error.message : 'Something went wrong.');
      });
  }

  onDateChange(event){
    this.bookingData.booking.serviceId = null;
    this.bookingData.booking.pickUpTime = event;
    this.getServiceByDate();
  }

  onNextClick() {
    this.bookingStep++;
  }

  onBackClick() {
    this.bookingStep--;
  }

  stripeDataChanged(event){
    this.stripeData = event;
  }
}
