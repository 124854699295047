import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NotificationService,
  NotificationStatus,
} from '../../../services/notification.service';
import { WidgetService } from '../../../services/widget.service';
import { DateTimezoneHelper } from '../../../utils/helper/date-timezone.helper';
import {
  StripeCardComponent,
  StripeFactoryService,
  StripeInstance,
} from 'ngx-stripe';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-takeaway-booking-summary',
  templateUrl: './takeaway-booking-summary.component.html',
  styleUrls: ['./takeaway-booking-summary.component.scss'],
})
export class TakeawayBookingSummaryComponent implements OnInit {
  @Input() bookingData;
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Output() stripeDataChange = new EventEmitter();
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  selectedService;
  selectedVenue;
  menuList = [];
  total = 0;
  bookingId;
  stripe: StripeInstance;
  nameOnCard;
  cancelToken;
  pickUpTime;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#66737f',
        color: '#66737f',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '0.9375rem',
        fontSmoothing: 'antialiased',
        padding: '10px',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#b8c1cc',
        },
      },
      invalid: {
        iconColor: '#fe5050',
        color: '#fe5050',
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
      },
    ],
  };
  @ViewChild('dialog') cardDialog;

  constructor(
    private widgetService: WidgetService,
    private notificationService: NotificationService,
    private stripeFactory: StripeFactoryService,
    private dialogService: NbDialogService,
    private route: Router,
    @Optional() private dialogRef: NbDialogRef<any>
  ) {}

  ngOnInit(): void {
    this.selectedService = this.widgetService.getSelectedService();
    this.selectedVenue = this.widgetService.getVenue().venueName;
    this.menuList = this.widgetService.getSelectedService().menu;
    this.menuList.forEach((obj) => {
      this.total = this.total + obj.price * obj.quantity;
    });
    this.pickUpTime = moment
      .utc(
        new DateTimezoneHelper().convertDateWithTimezone(
          this.bookingData.booking.pickUpTime.toISOString(),
          this.widgetService.getVenue().timezone
        )
      )
      .tz(this.widgetService.getVenue().timezone)
      .format('hh:mm A');
  }

  open() {
    this.dialogRef = this.dialogService.open(this.cardDialog, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }

  onPayment() {
    const bookingData = JSON.parse(JSON.stringify(this.bookingData));
    bookingData.booking.pickUpTime =
      new DateTimezoneHelper().convertDateWithTimezone(
        bookingData.booking.pickUpTime,
        this.widgetService.getVenue().timezone
      );
    if (this.widgetService.getVenue().payment.gateWayName === 'eway') {
      this.widgetService
        .getMenuEwayUrl(bookingData.venueId, bookingData)
        .then((res: any) => {
          window.open(res.url, '_blank');
          this.notificationService.showNotification(
            NotificationStatus.SUCCESS,
            res.message ? res.message : 'Booking added successfully.'
          );
        })
        .catch((err) => {
          console.log(err);
          this.notificationService.showNotification(
            NotificationStatus.ERROR,
            err.error.message ? err.error.message : 'Something went wrong'
          );
        });
    } else {
      this.widgetService
        .getMenuStripKey(bookingData.venueId, bookingData)
        .then((res: any) => {
          this.bookingId = res.bookingId;
          this.stripe = this.stripeFactory.create(res.key);
          this.stripeDataChange.emit({
            bookingId: this.bookingId,
            venueId: this.widgetService.getVenue()._id,
            stripeToken: this.stripe,
          });
          this.open();
        })
        .catch((err: any) => {
          console.log(err);
          this.notificationService.showNotification(
            NotificationStatus.ERROR,
            err.error.message ? err.error.message : 'Something went wrong'
          );
        });
    }
  }

  createToken(): void {
    const name = this.nameOnCard;
    this.stripe.createToken(this.card.element, { name }).subscribe((result) => {
      if (result.token) {
        // Use the token
        this.stripePay(result.token.id);
        this.cancelToken = result.token.id;
      } else if (result.error) {
        // Error creating the token
        console.log(result.error.message);
        this.notificationService.showNotification(
          NotificationStatus.ERROR,
          result.error.message
        );
      }
    });
  }

  stripePay(token) {
    const data = {
      menuBookingId: this.bookingId,
      stripeToken: token,
      venueId: this.widgetService.getVenue()._id,
    };
    console.log(data);
    this.widgetService
      .getMenuPayment(data)
      .then((res) => {
        this.closeDialog();
        this.route.navigate(
          ['online-order', this.widgetService.getVenue()._id],
          { queryParams: { payment: true } }
        );
      })
      .catch((err) => {
        console.log(err);
        this.widgetService.paymentStatusChange.next(false);
      });
  }

  cancelBooking() {
    const data = {
      bookingId: this.bookingId,
      stripeToken: this.cancelToken,
      cancelled: true,
      takeaway: true,
    };
    this.widgetService
      .setPayment(data, false)
      .then((res: any) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
