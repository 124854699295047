<nb-layout>
  <nb-layout-column>
    <div class="bottom-main-div">
      <div class="header-div">
      </div>
      <div class="preview-content">
        <div class="logo-main">
          <div class="logo-image-div">
            <img [src]="venueLogo"/>
          </div>
          <div class="venue-name mt-20">{{venueName}}</div>
        </div>
        <div class="main-content" *ngIf="!isEdit">
          <div *ngIf="!isStatusUpdated" class="main-content mt-70">
            <p>We have received your booking. Please confirm your booking here.</p>
            <div class="d-flex">
              <button (click)="updateBooking('Confirmed')" nbButton status="success">Confirm</button>
              <button *ngIf="editData && editData.bookingType === 'widget' && allowEdit" (click)="updateBooking('edit')" class="ml-10" nbButton status="warning">Modify</button>
              <button *ngIf="allowCancel" (click)="updateBooking('Cancelled')" class="ml-10" nbButton status="danger">Decline</button>
            </div>
          </div>
          <div *ngIf="isStatusUpdated && status === 'Confirmed'" class="main-content mt-70">
            <p>Your booking status is updated.</p>
          </div>
          <div *ngIf="isStatusUpdated && status === 'Cancelled'" class="main-content mt-70">
            <p>Your booking cancelled successfully.</p>
            <p>We hope to see you next time soon.</p>
          </div>
        </div>
      </div>
    </div>

  </nb-layout-column>
</nb-layout>
