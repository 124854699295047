<div class="w-100 d-flex just-center">
  <div class="main-content-customer">
    <div class="customer-content mt-20 w-100">
      <div class="row w-100 col-gap-16">
        <div class="col-6">
          <label>Name*</label>
          <input [(ngModel)]="bookingData.customer.name" class="mt-5" fieldSize="large" fullWidth nbInput
                 placeholder="Enter name">
        </div>
        <div class="col-6">
          <label>Phone*</label>
          <phone-input [(phoneNumber)]="bookingData.customer.contactNumber"></phone-input>
        </div>
        <div class="col-6">
          <label>Email*</label>
          <input [(ngModel)]="bookingData.customer.email" class="mt-5" fieldSize="large" fullWidth
                 nbInput placeholder="Enter email">
        </div>
        <div class="col-6">
          <label>Address</label>
          <input [(ngModel)]="bookingData.customer.address" class="mt-5" fieldSize="large" fullWidth
                 nbInput placeholder="Enter address">
        </div>
      </div>
    </div>
    <div class="d-flex align-center">
      <nb-checkbox status="basic" class="terms__conditions-fonts" name="termsAndConditions" [(ngModel)]="isAcceptedTermsAndConditions" required>
      </nb-checkbox>
      <div class="ml-10 cursor-pointer" (click)="isAcceptedTermsAndConditions=!isAcceptedTermsAndConditions">I agree to the Bookya </div>
      <a href="https://bookya.com.au/privacy-policy" target="_blank" class="cursor-pointer ml-6">terms and conditions.</a>
    </div>
    <div class="d-flex just-end pt-10">
      <button (click)="onBackClick()" class="calendar-button mr-10" nbButton status="basic">Back</button>
      <button (click)="onNextClick()" [ngClass]="{'button-disabled': !isAcceptedTermsAndConditions}" class="calendar-button" nbButton status="success">Next</button>
    </div>
  </div>
</div>
