import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {WidgetService} from '../../../services/widget.service';
import {NotificationService, NotificationStatus} from '../../../services/notification.service';
import {BookingData} from '../../../utils/model/bookingData';
import {BookingValidationHelper} from '../../../utils/helper/booking-validation.helper';
import {Customer} from '../../../utils/model/customer';

@Component({
  selector: 'custome-details',
  templateUrl: './custome-details.component.html',
  styleUrls: ['./custome-details.component.scss']
})
export class CustomeDetailsComponent implements OnInit, OnChanges {

  @Input() bookingData: BookingData;
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Output() customerDataChange = new EventEmitter();
  @Input() customerData: Customer;
  badgesList = [];
  isAcceptedTermsAndConditions = false;
  constructor(private widgetService: WidgetService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.getAllBadges();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  onBackClick() {
    if( this.bookingData.bookingOptions.length === 0){
      this.onBackClickChange.emit();
      this.onBackClickChange.emit();
    } else{
      this.onBackClickChange.emit();
    }
  }

  onNextClick() {
    const error = new BookingValidationHelper().customerValidation(this.customerData);
    if (error && error.errStatus) {
      this.notificationService.showNotification(NotificationStatus.ERROR, error.error);
      return true;
    }
    if(!this.isAcceptedTermsAndConditions){
      this.notificationService.showNotification('warning', "You need to agree to the terms and conditions before proceeding");
      return;
    }
    if (!this.bookingData.customerId) {
      this.addCustomer();
    }
    this.bookingData.customerNote = this.customerData.notes.customerNotes;
    this.bookingData.managerNote = this.customerData.notes.managerNotes;
    this.customerDataChange.emit(this.customerData);
    this.onNextClickChange.emit();
  }

  checkForCustomer() {
    this.widgetService.getCustomerByEmail(this.customerData.email)
      .then((res: any) => {
        this.bookingData.customerId = res.data._id;
      })
      .catch(err => {
        this.bookingData.customerId = null;
      });
  }

  addCustomer() {
    this.widgetService.addCustomer(this.customerData)
      .then((res: any) => {
        this.bookingData.customerId = res.data._id;
        this.bookingData.customerNote = this.customerData.notes.customerNotes;
      })
      .catch(err => {
        this.notificationService.showNotification(NotificationStatus.ERROR, err.error.message ? err.error.message : 'Something went wrong.');
      });
  }

  getAllBadges() {
    this.widgetService.getAllBadge()
      .then((res: any) => {
        this.badgesList = res.data;
        if(this.bookingData.badges.length){
          this.bookingData.badges.forEach(bdg => {
            this.badgesList.forEach((badge, i) => {
              if(bdg.hasOwnProperty('badgeId')){
                if(badge.badges._id === bdg.badgeId){
                  this.onBadgeSelect(badge , i);
                }
              }
              else {
                if (badge.badges._id === bdg['badges']._id) {
                  this.onBadgeSelect(badge, i);
                }
              }
            });
          });
        }
      })
      .catch((err: any) => {
        this.notificationService.showNotification(NotificationStatus.ERROR, err.error.message ? err.error.message : 'Something went wrong.');
      });
  }

  onBadgeSelect(badgeItem: any, index: number) {
    this.bookingData.badges = [];
    this.badgesList.forEach((badge, i) => {
      if (i === index) {
        badge.isSelected = !badge.isSelected;
      }
      if (badge.isSelected) {
        this.bookingData.badges.push({
          badgeId: badge.badges._id,
          name: badge.badges.name,
          description: ''
        });
      }
    });
  }
}
