import * as moment from 'moment-timezone';

export class DateTimezoneHelper {
  convertDateWithTimezone(date, timezone) {
    const offset = moment.tz(date, timezone).utcOffset();
    return moment(date).utcOffset(offset, true).toISOString();
  }

  convertUTCtoTimeZone(date, timezone) {
    const offset = moment.tz(date, timezone).utcOffset();
    const localOffset = moment.tz(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone).utcOffset();
    return new Date(new Date(date).getTime() + (offset - localOffset) * 60 * 1000);
  }

  removeLocalTimezoneOffset(date, timezone) {
    const offset = moment.tz(date, timezone).utcOffset();
    const localOffset = moment.tz(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone).utcOffset();
    return new Date(new Date(date).getTime() - (offset - localOffset) * 60 * 1000);
  }
}
