<div class="w-100 content-height d-flex just-center">
  <div class="main-content-customer ">
    <div class="mt-20 w-100 customer-container">
      <div class="row w-100 just-evenly">
        <div class="col-4">
          <label>Name*</label>
          <input [(ngModel)]="customerData.name" class="mt-5" fieldSize="large" fullWidth nbInput
                 placeholder="Enter name">
        </div>
        <div class="col-4">
          <label>Phone*</label>
          <phone-input [(phoneNumber)]="customerData.contactNumber"></phone-input>
        </div>
        <div class="col-4">
          <label>Email*</label>
          <input (blur)="checkForCustomer()" [(ngModel)]="customerData.email" class="mt-5" fieldSize="large" fullWidth
                 nbInput placeholder="Enter email">
        </div>
      </div>
      <div class="row mt-10 just-evenly">
        <div class="col-4">
          <label>Company Name</label>
          <input [(ngModel)]="customerData.company" class="mt-5" fieldSize="large" fullWidth nbInput
                 placeholder="Enter company">
        </div>
        <div class="col-8">
          <label>Note</label>
          <input [(ngModel)]="customerData.notes.customerNotes" class="mt-5" fieldSize="large" fullWidth nbInput
                 placeholder="Enter note">
        </div>
      </div>
      <div class="common-title mt-20">
        Special Requirements
      </div>
      <div *ngIf="badgesList" class="d-flex col-gap-16 w-100">
        <div class="d-flex f-wrap gap-5">
          <div (click)="onBadgeSelect(badgeItem, bi)" *ngFor="let badgeItem of badgesList; let bi= index"
               [ngClass]="{'selected': badgeItem.isSelected}" class="common-chips mt-10">
            {{badgeItem.badges.name}}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="d-flex align-center mt-20">
        <nb-checkbox status="basic" class="terms__conditions-fonts" name="termsAndConditions" [(ngModel)]="isAcceptedTermsAndConditions" required>
        </nb-checkbox>
        <div class="ml-10 cursor-pointer" (click)="isAcceptedTermsAndConditions=!isAcceptedTermsAndConditions">I agree to the Bookya </div>
        <a href="https://bookya.com.au/privacy-policy" target="_blank" class="cursor-pointer ml-6">terms and conditions.</a>
      </div>
      <div class="d-flex just-end pt-10">
        <button (click)="onBackClick()" class="calendar-button mr-10" nbButton status="basic">Back</button>
        <button (click)="onNextClick()" [ngClass]="{'button-disabled': !isAcceptedTermsAndConditions}" class="calendar-button" nbButton status="success">Next</button>
      </div>
    </div>
  </div>
</div>
<ng-template #templateRef>
  <div class="p-10" style="width: 500px">
    <p class="m-0 mb-10">Bookya</p>
  </div>
</ng-template>
