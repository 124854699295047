import {HttpClient} from '@angular/common/http';
import {Urls} from '../utils/urls';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class WidgetService {
  selectedService;
  selectedSection;
  paymentData;
  paymentStatusChange = new Subject();

  constructor(private http: HttpClient) {
  }

  setVenue(venue) {
    localStorage.setItem('venue', JSON.stringify(venue));
  }

  getVenue() {
    return JSON.parse(localStorage.getItem('venue'));
  }

  setWidgetSettings(widgetSettings) {
    localStorage.setItem('widgetSettings', JSON.stringify(widgetSettings));
  }

  getWidgetSettingsFromLocal() {
    return JSON.parse(localStorage.getItem('widgetSettings'));
  }

  setSelectedService(service) {
    this.selectedService = service;
  }

  getSelectedService() {
    return this.selectedService;
  }
  setSelectedSection(section) {
    this.selectedSection = section;
  }
  getSelectedSection() {
    return this.selectedSection;
  }
  setBookingType(type) {
    localStorage.setItem('bookingType', type);
  }
  getBookingType() {
    return localStorage.getItem('bookingType');
  }
  setBookingData(data) {
    localStorage.setItem('bookingData', data);
  }
  getBookingData() {
    return localStorage.getItem('bookingData');
  }
  getWidgetSettings(venueId) {
    return new Promise((resolve, reject) => {
      this.http
        .get(Urls.widget + '/' + venueId )
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getServiceByDate(date, timezone , bookingType?) {
    return new Promise((resolve, reject) => {
      this.http
        .get(Urls.widget + '/services-events/' + this.getVenue()._id + '/by-day?timezone=' + timezone + '&date=' + date + '&isApp=false' + '&bookingType=' +  bookingType)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getSectionByService(serviceId, data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.services + '/online-widget/' + this.getVenue()._id + '/' + serviceId + '/sections', data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getEventByIdAndDate(eventId, data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.baseUrl + 'event/' + this.getVenue()._id + '/' + eventId + '/by-date' + '/widget' + '?timezone=' + this.getVenue().timezone, data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getBookingTimeSlots(serviceId, data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.services + '/online-widget/' + this.getVenue()._id + '/' + serviceId + '/slots', data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getCustomerByEmail(emailId) {
    return new Promise((resolve, reject) => {
      this.http
        .get(Urls.customer + '/online-widget/' + this.getVenue()._id + '/email?email=' + emailId)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  addCustomer(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.customer + '/online-widget/' + this.getVenue()._id, data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  updateCustomer(data, customerId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.customer + '/online-widget/' + this.getVenue()._id + '/' + customerId, data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getAllBadge() {
    return new Promise((resolve, reject) => {
      this.http
        .get(Urls.badges + '/online-widget/' + this.getVenue()._id + '?online=true')
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getStripeKey(data, emailPayment) {
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.payment + '/' + this.getVenue()._id + '/stripe/publishableKey?emailPayment=' + emailPayment, data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  addBooking(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.bookings + '/online-widget/' + this.getVenue()._id, data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  setPayment(data, emailPayment) {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.payment + '/stripe?emailPayment=' + emailPayment, data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getEwayUrl(data, emailPayment) {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.payment + '/' + this.getVenue()._id + '/eWay?emailPayment=' + emailPayment, data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getMenuEwayUrl(venueId , data){
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.menuPayment + '/' + venueId + "/eWay", data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getMenuStripKey(venueId,  data){
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.menuPayment + '/' + venueId + "/stripe/publishableKey", data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getMenuPayment(data){
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.menuPayment + '/stripe' , data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  paymentSummary(data){
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.paymentSummary , data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getBookingById(venueId , bookingId){
    return new Promise((resolve, reject) => {
      this.http
        .get(Urls.bookings + '/' + venueId + '/' + bookingId)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  updateBooking(bookingData , token){
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.bookings + '/online-widget/' + this.getVenue()._id + '?token=' + token , bookingData)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  checkTableAvailability(data) {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.bookings + '/' + this.getVenue()._id + '/check-table-availability', data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
