<div class="w-100 content-height d-flex just-center">
  <div class="padding w-96">
    <div class="d-flex align-center mb-10 pr-10 pl-10">
      Number of booking options must match number of people in booking.
      <div class="ml-6 count_content" [ngClass]="{'count__match': bookingOptionTotalQuantity == bookingData.people, 'count_not_match': bookingOptionTotalQuantity != bookingData.people}">
        <nb-icon icon="checkmark-circle-2" *ngIf="bookingOptionTotalQuantity == bookingData.people" class="mr-6"></nb-icon> <nb-icon *ngIf="bookingOptionTotalQuantity != bookingData.people"  icon="info" class="mr-6"></nb-icon> <span class="pr-10">{{bookingOptionTotalQuantity + "/" + bookingData.people}}</span>
      </div>
    </div>
    <nb-accordion class="main-content-selector">
      <nb-accordion-item *ngFor="let bookingOption  of bookingData.bookingOptions; let index = index">
        <nb-accordion-item-header>
          {{bookingOption.name}} - ${{bookingData.bookingOptions[index].pricePerPerson}}
          <div class="d-flex align-center ml-auto mr-10 pr-10">
          <nb-icon [ngClass]="{'disable': bookingOption.quantity <= 0}" (click)="decreasePeople(index); $event.stopPropagation()" class="mr-10 cursor-pointer" icon="minus-circle-outline"></nb-icon>
            <input type="number" [(ngModel)]="bookingData.bookingOptions[index].quantity" class="people-input booking-option-input" nbInput placeholder="00" disabled>
            <nb-icon [ngClass]="{'disable': bookingOptionTotalQuantity >= bookingData.people}" (click)="increasePeople(index); $event.stopPropagation()" class="ml-10 cursor-pointer" icon="plus-circle-outline"></nb-icon>
           <p class="t-price"> $ {{bookingData.bookingOptions[index].quantity * bookingData.bookingOptions[index].pricePerPerson}} </p>
        </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body >
          <div *ngIf="bookingOption.widgetDescription" [innerHTML]="bookingOption.widgetDescription">
          </div>
          <div class="main-card mt-20" *ngIf="bookingOption.childBookingOption.length">
         <ng-container *ngFor="let bookingOptions of bookingOption.bookingList;let  i = index">
           <div class="booking-card" [ngClass]="{'disable': bookingOption.quantity <= 0}">
             <ng-container *ngFor="let child of bookingOptions;let  ind = index">
                   <div class="booking-quantity align-center">
                     <p>{{child.name}}</p>
                     <div class="d-flex align-center ml-auto mr-10 pr-10">
                       <nb-icon [ngClass]="{'disable': child.quantity <= 0}" (click)="decreasePeople(index, i, ind, 'child'); $event.stopPropagation()" class="mr-10 cursor-pointer" icon="minus-circle-outline"></nb-icon>
                       <input type="number" [(ngModel)]="child.quantity" min="10" class="people-input" nbInput placeholder="00" disabled>
                       <nb-icon (click)="increasePeople(index, i, ind, 'child'); $event.stopPropagation()" class="ml-10 cursor-pointer" icon="plus-circle-outline"></nb-icon>
                     </div>
                 </div>
             </ng-container>
           </div>
         </ng-container>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <div class="button-div d-flex just-end pt-10">
      <button (click)="onBackClick()"  class="calendar-button mr-10" nbButton status="basic">Back</button>
      <button (click)="onNextClick()" [disabled]="bookingOptionTotalQuantity != bookingData.people" class="calendar-button" nbButton status="success">Next</button>
    </div>
  </div>
</div>
