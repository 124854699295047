<!--<div class="top-details calendar-date">
  <div class="d-flex align-center just-between">
    <nb-icon (click)="onPreDate()" [options]="{color: '#013243', height: '24px', width: '20px'}" class="cursor-pointer"
             icon="arrow-left-outline"></nb-icon>
    <div class="date-div-main">{{bookingData.bookingDateTime | date : 'EEEE, dd MMMM y'}}</div>
    <nb-icon (click)="onNextDate()" [options]="{color: '#013243',height: '24px', width: '20px'}" class="cursor-pointer"
             icon="arrow-right-outline"></nb-icon>
  </div>
</div>-->
<div class="w-100 d-flex just-center">
  <div class="main-content-date-selector">

    <div class="mt-20 w-100">
      <nb-calendar
        (dateChange)="onDateChange($event)"
        [(date)]="bookingData.bookingDateTime"
        [min]="lastDate"
        [boundingMonth]="true"
        [showNavigation]="true"
        size="medium"
        [visibleDate]="bookingData.bookingDateTime">
      </nb-calendar>
    </div>
    <div class="w-95 d-flex just-end mt-20 next-button">
      <button (click)="onNextClick()" [disabled]="!isNext" class="calendar-button" nbButton status="success">Next</button>
    </div>
  </div>
</div>

