<div class="w-100 d-flex just-center">
  <div class="main-summary-content">
    <div class="mt-20 w-100 container">
      <div class="row col-gap-16">
        <div class="col-6 summary-content">
          <label class="title">Details</label>
          <table class="w-100 mt-10">
            <tbody>
            <tr>
              <td class="header d-flex">Restaurant</td>
              <td class="word-break"> {{selectedVenue}}</td>
            </tr>
            <tr>
              <td class="header d-flex">Date</td>
              <td class="word-break"> {{bookingData.booking.pickUpTime | date}}</td>
            </tr>
            <tr>
              <td class="header d-flex">People</td>
              <td class="word-break"> {{bookingData.people}}</td>
            </tr>
            <tr>
              <td class="header d-flex">Service</td>
              <td class="word-break">{{selectedService.name}}</td>
            </tr>
            <tr>
              <td class="header d-flex">Time</td>
              <td class="word-break">{{pickUpTime}}</td>
            </tr>
            <tr>
              <td class="header d-flex">Name</td>
              <td class="word-break"> {{bookingData.customer.name}}</td>
            </tr>
            <tr>
              <td class="header d-flex">Phone</td>
              <td class="word-break"> {{bookingData.customer.contactNumber}}</td>
            </tr>
            <tr>
              <td class="header d-flex">Email</td>
              <td class="word-break">{{bookingData.customer.email}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6 summary-content">
          <label class="title">Order Summary</label>
          <div class="mt-20">
            <table class="order-summary-table w-100">
              <thead>
              <tr>
                <th>Item</th>
                <th>Each</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let menu of bookingData.booking.menu">
                  <td>{{menu.name}}</td>
                  <td>{{'$' + menu.price}}</td>
                  <td>{{menu.quantity}}</td>
                  <td>{{'$' + (menu.price * menu.quantity)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-20">
            <div class="total-main mt-30">
              <table class="order-summary-table-payment w-100">
                <tbody>
                <tr>
                  <td>Total</td>
                  <td style="width: 30%"></td>
                  <td>
                    {{total}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex just-end pt-10">
      <button (click)="onBackClick()" class="calendar-button mr-10" nbButton status="basic">Back</button>
      <button (click)="onPayment()" class="calendar-button" nbButton status="success">Book Now</button>
    </div>
  </div>
</div>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card class="stripe-dialog">
    <nb-card-header>Pay</nb-card-header>
    <nb-card-body>
      <input [(ngModel)]="nameOnCard" fullWidth nbInput placeholder="Name on Card">
      <div class="stripe-card-input mt-10">
        <ngx-stripe-card [elementsOptions]="elementsOptions" [options]="cardOptions" [stripe]="stripe"
                         class="w-100"></ngx-stripe-card>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button (click)="createToken()" nbButton>Pay Now</button>
      <button (click)="cancelBooking()" class="ml-10" nbButton>Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
