import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationStatus , NotificationService} from "../../../services/notification.service";
import {BookingValidationHelper} from "../../../utils/helper/booking-validation.helper";

@Component({
  selector: 'app-takeaway-customer',
  templateUrl: './takeaway-customer.component.html',
  styleUrls: ['./takeaway-customer.component.scss']
})
export class TakeawayCustomerComponent implements OnInit {
  @Input() bookingData;
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  isAcceptedTermsAndConditions = false;
  constructor(private notificationService: NotificationService) {
  }

  ngOnInit(): void {

  }

  onBackClick(){
    this.onBackClickChange.emit();
  }

  onNextClick() {
    const error = new BookingValidationHelper().customerValidation(this.bookingData.customer);
    if (error && error.errStatus) {
      this.notificationService.showNotification(NotificationStatus.ERROR, error.error);
      return true;
    }
    if(!this.isAcceptedTermsAndConditions){
      this.notificationService.showNotification('warning', "You need to agree to the terms and conditions before proceeding");
      return;
    }
    this.onNextClickChange.emit();
  }

}
