<div
  class="w-100 content-height d-flex just-center"
  [nbSpinner]="isLoading"
  nbSpinnerStatus="success"
>
  <div class="main-summary-content">
    <div class="mt-20 w-100 summary-container">
      <div class="row just-evenly">
        <div class="col-6 summary-content">
          <label class="title">Details</label>
          <table class="w-100 mt-10">
            <tbody>
              <tr>
                <td class="header d-flex">Restaurant</td>
                <td class="word-break">{{ selectedVenue }}</td>
              </tr>
              <tr>
                <td class="header d-flex">Date</td>
                <td class="word-break">{{ bookingDate | date }}</td>
              </tr>
              <tr>
                <td class="header d-flex">People</td>
                <td class="word-break">{{ bookingData.people }}</td>
              </tr>
              <tr>
                <td class="header d-flex">Service</td>
                <td class="word-break">
                  {{
                    selectedService.name
                      ? selectedService.name
                      : selectedService.event.eventName + "(event)"
                  }}
                </td>
              </tr>
              <tr>
                <td class="header d-flex">Section</td>
                <td class="word-break">
                  {{ selectedSection ? selectedSection.sectionName : "-" }}
                </td>
              </tr>
              <tr>
                <td class="header d-flex">Time</td>
                <td class="word-break">{{ bookingDate | date: "hh:mm a" }}</td>
              </tr>
              <tr>
                <td class="header d-flex">Name</td>
                <td class="word-break">{{ customerData.name }}</td>
              </tr>
              <tr>
                <td class="header d-flex">Phone</td>
                <td class="word-break">{{ customerData.contactNumber }}</td>
              </tr>
              <tr>
                <td class="header d-flex">Email</td>
                <td class="word-break">{{ customerData.email }}</td>
              </tr>
              <tr>
                <td class="header d-flex">Company Name</td>
                <td class="word-break">{{ customerData.company }}</td>
              </tr>
              <tr>
                <td class="header d-flex">Note</td>
                <td class="word-break">
                  {{ customerData.notes.customerNotes }}
                </td>
              </tr>
              <tr>
                <td class="header d-flex">Special Requirements</td>
                <td class="word-break">{{ badges }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6 summary-content">
          <label class="title">Booking Summary</label>
          <div class="mt-10">
            <label class="header mr-10">Payment Type:</label
            ><span>{{ selectedService.paymentType }}</span>
          </div>
          <div *ngIf="!isFromEmail" class="mt-10 table-summary">
            <table class="order-summary-table w-100">
              <thead>
                <tr *ngIf="selectedService.paymentType !== 'preauth'">
                  <th>Item</th>
                  <th>Each</th>
                  <th>Qty</th>
                  <th>Total</th>
                </tr>
                <tr
                  *ngIf="selectedService.paymentType === 'preauth'"
                  class="d-flex just-between"
                >
                  <th>Item</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngIf="
                    selectedService.paymentType !== 'booking-option' &&
                    selectedService.paymentType !== 'preauth'
                  "
                >
                  <td>
                    {{
                      selectedService.name
                        ? selectedService.name
                        : selectedService.event.eventName
                    }}
                  </td>
                  <td>
                    {{
                      selectedService.payment?.pricePerPerson
                        ? "$" + selectedService.payment.pricePerPerson
                        : "0"
                    }}
                  </td>
                  <td>{{ bookingData.people }}</td>
                  <td>
                    {{
                      selectedService.payment?.pricePerPerson
                        ? "$" +
                          bookingData.people *
                            selectedService.payment.pricePerPerson
                        : "$0"
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="selectedService.paymentType === 'preauth'"
                  class="d-flex just-between"
                >
                  <td>{{ selectedService.name }}</td>
                  <td>
                    {{
                      selectedService.payment?.pricePerPerson
                        ? "$" + selectedService.payment.pricePerPerson
                        : "$0"
                    }}
                  </td>
                </tr>
                <tr *ngFor="let bookingOption of bookingData.bookingOptions">
                  <td colspan="4">
                    <table
                      class="order-summary-table w-100"
                      *ngIf="bookingOption.quantity > 0"
                    >
                      <tr class="d-flex just-between">
                        <td>{{ bookingOption.name }}</td>
                        <td class="w-13">
                          ${{ bookingOption.pricePerPerson }}
                        </td>
                        <td class="w-13 pl-10">{{ bookingOption.quantity }}</td>
                        <td class="w-13">
                          ${{
                            bookingOption.quantity *
                              bookingOption.pricePerPerson
                          }}
                        </td>
                      </tr>
                      <tr
                        class="d-flex just-between"
                        *ngFor="
                          let childBookingOption of bookingOption.childBookingOption
                        "
                      >
                        <td class="ml-6">{{ childBookingOption.name }}</td>
                        <td class="w-13">
                          ${{ childBookingOption.pricePerPerson }}
                        </td>
                        <td class="w-13 pl-10">
                          {{ childBookingOption.quantity }}
                        </td>
                        <td class="w-13">
                          ${{
                            childBookingOption.quantity *
                              childBookingOption.pricePerPerson
                          }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!isFromEmail" class="total-main mt-30">
            <table class="order-summary-table-payment w-100">
              <tbody>
                <tr class="d-flex just-between" *ngIf="total >= 0">
                  <td>Total</td>
                  <td>
                    {{ "$" + total }}
                  </td>
                </tr>
                <tr class="d-flex just-between" *ngIf="discountAmount >= 0">
                  <td>Discount Amount</td>
                  <td>
                    {{ "$" + discountAmount }}
                  </td>
                </tr>
                <tr class="d-flex just-between" *ngIf="paidAmount >= 0">
                  <td>Grand Total</td>
                  <td>
                    {{ "$" + paidAmount }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="isFromEmail" class="mt-20">
            <table class="order-summary-table w-100">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Payment Type</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{
                      selectedService.name
                        ? selectedService.name
                        : selectedService.event.eventName
                    }}
                  </td>
                  <td>
                    {{
                      bookingData.payment
                        ? (bookingData.payment.type | titlecase)
                        : "0"
                    }}
                  </td>
                  <td>
                    {{
                      bookingData.payment
                        ? "$" + bookingData.payment.amount
                        : "0"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="total-main mt-30">
              <table class="order-summary-table w-100">
                <tbody>
                  <tr>
                    <td>Total</td>
                    <td style="width: 30%"></td>
                    <td>
                      {{
                        bookingData.payment
                          ? "$" + bookingData.payment.amount
                          : "0"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex just-end pt-10">
      <button
        (click)="onBackClick()"
        class="calendar-button mr-10"
        nbButton
        status="basic"
      >
        Back
      </button>
      <button
        (click)="onPayment()"
        [ngClass]="{ disable_booknow: isBooked }"
        class="calendar-button"
        nbButton
        status="success"
      >
        {{ token ? "Update Booking" : "Book Now" }}
      </button>
    </div>
  </div>
</div>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card class="stripe-dialog">
    <nb-card-header>Pay</nb-card-header>
    <nb-card-body class="position-relative">
      <div
        *ngIf="!isLoaded"
        [nbSpinner]="!isLoaded"
        class="status-primary"
        nbSpinnerSize="giant"
        nbSpinnerStatus="primary"
        style="height: 200px; width: 100%; position: absolute; top: 0; left: 0"
      ></div>
      <ng-container>
        <input
          [(ngModel)]="nameOnCard"
          fullWidth
          nbInput
          placeholder="Name on Card"
        />
        <div class="stripe-card-input mt-10">
          <ngx-stripe-card
            [elementsOptions]="elementsOptions"
            [options]="cardOptions"
            [stripe]="stripe"
            class="w-100"
          ></ngx-stripe-card>
        </div>
      </ng-container>
    </nb-card-body>
    <nb-card-footer>
      <button (click)="createToken()" nbButton>Pay Now</button>
      <button (click)="cancelBooking()" class="ml-10" nbButton>Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #inActiveDialog let-data let-ref="dialogRef">
  <nb-card class="stripe-dialog">
    <nb-card-header>Pay</nb-card-header>
    <nb-card-body class="position-relative">
      <div>Due to inactivity your booking has been canceled</div>
    </nb-card-body>
    <nb-card-footer>
      <button (click)="closeAndBack()" class="ml-10" nbButton>Book Again</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
