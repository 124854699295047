<div class="w-100 content-height d-flex just-center">
  <div class="main-content-service-selector w-100">
    <div class="mt-20 w-100 content-div" id="service-selector">
      <div class="d-flex f-wrap col-gap-16 w-100">
        <div class="w-100 d-flex flex-dir">
          <span class="common-title">People</span>
          <div class="mt-10 d-flex align-center">
            <nb-icon (click)="decreasePeople()" class="mr-10 cursor-pointer" icon="minus-circle-outline"></nb-icon>
            <input (blur)="changeNoPeople($event)" [(ngModel)]="bookingData.people" class="people-input" nbInput placeholder="00">
            <nb-icon (click)="increasePeople()" class="ml-10 cursor-pointer" icon="plus-circle-outline"></nb-icon>
          </div>
        </div>
        <div class="w-100 service-div">
          <div class="common-title">Services</div>
          <div class="d-flex f-wrap gap-5">
            <div (click)="!service.isSelected ? onServiceSelected(service, si , 'create'):''" *ngFor="let service of serviceList; let si = index"
                 [ngClass]="{'selected': service.isSelected}" class="common-chips mt-10">
              {{service.name}}
              <div *ngIf="service.event">
                {{service.event?.eventName + " "}}Event
              </div>
            </div>
          </div>
          <div *ngIf="currentSelectedService" class="pt-10">
            <div *ngIf="currentSelectedService.type==='Event'" [innerHTML]="currentSelectedService.event.widgetDescription">
            </div>
            <div *ngIf="currentSelectedService.type==='Services'" [innerHTML]="currentSelectedService.description">
            </div>
          </div>

        </div>
        <div *ngIf="sectionList.length" class="w-100 service-div">
          <div class="common-title">Sections</div>
          <div class="d-flex f-wrap gap-5" [nbSpinner]="isSectionLoading" nbSpinnerStatus="success">
            <div (click)="!section.isSelected ? onSectionSelect(section, secI, 'create'):''" *ngFor="let section of sectionList; let secI = index"
                 [ngClass]="{'selected': section.isSelected}" class="common-chips mt-10">
              {{section.sectionName}}
            </div>
          </div>
        </div>
        <div *ngIf="isSectionLoading && sectionList.length <1" class="w-100 service-div">
          <div class="common-title">Sections</div>
          <div class="loader d-flex f-wrap gap-5" [nbSpinner]="isSectionLoading" nbSpinnerStatus="success">
          </div>
        </div>
        <div *ngIf="isLoading && timeSlotList.length <1" class="w-100 service-div">
          <div class="common-title">Time</div>
          <div class="loader d-flex f-wrap gap-5" [nbSpinner]="isLoading" nbSpinnerStatus="success">
          </div>
        </div>
        <div *ngIf="timeSlotList.length" class="w-100 service-div">
          <div class="common-title">Time</div>
          <div class="d-flex f-wrap gap-5">
            <div class="d-flex f-wrap gap-5">
              <div (click)=" !timeslot.isSelected?onTimeSlotSelected(timeslot, ti, 'create'):''" *ngFor="let timeslot of timeSlotList; let ti = index"
                   [ngClass]="{'selected': timeslot.isSelected, 'slot__unavailable-disable': timeslot.timeSlot.tables && !timeslot.timeSlot.tables.length}" class="common-chips mt-10">
                {{timeslot.timeSlot.timeslot | timeformat}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="bookingData.timeslot && bookingOptionList.length" class="d-flex flex-dir col-gap-16 w-100">
        <div class="common-title mt-20">Available Booking Options</div>
        <div class="d-flex f-wrap gap-5">
          <ng-container *ngFor="let bookingOptionList of bookingOptionList; let bi = index">
            <input class="checkbox" type="checkbox" [id]="'booking-option' + bi" hidden [(ngModel)]="bookingOptionList.isSelected" (click)="getBookingOption(bookingOptionList)" >
            <label [for]="'booking-option' + bi"  class="common-chips mt-10">
                {{bookingOptionList.name}}
            </label>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="d-flex just-end pt-10">
      <button (click)="onBackClick()" class="calendar-button mr-10" nbButton status="basic">Back</button>
      <button (click)="onNextClick()" [disabled]="!isNext" class="calendar-button" nbButton status="success">Next</button>
    </div>
  </div>
</div>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card class="policy_aggrement">
    <nb-card-header>Policy Agreement</nb-card-header>
    <nb-card-body class="container__scroll">
      <ng-container *ngIf="currentSelectedService.type==='Services'" >
        <span [innerHTML]="currentSelectedService.policyAgreement"></span>
      </ng-container>
      <ng-container *ngIf="currentSelectedService.type==='Event'" >
        <span [innerHTML]="currentSelectedService.settings.policyAgreementNote"></span>
      </ng-container>
    </nb-card-body>
    <nb-card-footer>
      <nb-checkbox (checkedChange)="onPolicyAgreement($event)"  status="primary">I agree to the policy Agreement</nb-checkbox>
    </nb-card-footer>
  </nb-card>
</ng-template>
