import {environment} from '../environments/environment';

export class Urls{
  static baseUrl = environment.apiUrl;
  static widget = Urls.baseUrl + "widgets/online-widget";
  static images = Urls.baseUrl + "images";
  static services = Urls.baseUrl + "services";
  static customer = Urls.baseUrl + "customer";
  static badges = Urls.baseUrl + "badges";
  static payment = Urls.baseUrl + 'payment';
  static bookings = Urls.baseUrl + 'bookings';
  static venueList = Urls.baseUrl + 'widgets/online-widget/all-venue';
  static menuPayment = Urls.baseUrl + 'menu-booking';
  static paymentSummary = Urls.baseUrl + 'widgets/online-widget/payment-summary';
}
